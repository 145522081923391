import { Formik } from "formik"
import React, { useState } from "react"
import { fetchApi } from "../lib/fetcher"

export const AccountLinkCodeShareForm = () => {
  // web share api が使える場合
  const isWebShareApiSupported = typeof navigator.share === "function"

  return (
    <>
      {isWebShareApiSupported && (
        <div className="mt-4">
          <Formik
            initialValues={{ general: null }}
            onSubmit={async (_, actions) => {
              try {
                // 連携IDを発行
                const { link_code } = await fetchApi("/account_link_codes", "POST", null)
                navigator.share({
                  title: "連携したいブラウザで開いてください",
                  url: `${window.location.origin}/account_link_codes?link_code=${link_code}`,
                })
              } catch (error) {
                if (error.data && error.data.message) {
                  actions.setErrors({
                    general: error.data.message,
                  })
                } else {
                  actions.setFieldError("general", "エラーが発生しました")
                }
              } finally {
                actions.setSubmitting(false)
              }
            }}
          >
            {({ errors, handleSubmit, isSubmitting }) => (
              <form>
                <div className="text-center">
                  <button
                    onClick={() => {
                      handleSubmit()
                    }}
                    disabled={isSubmitting}
                    className="text-blue-500 underline"
                  >
                    データを引き継いだまま
                    <br />
                    他のブラウザで開くにはこちら＞
                  </button>
                </div>
                {errors.general && (
                  <div className="mt-4 text-center text-red-500">{errors.general}</div>
                )}
              </form>
            )}
          </Formik>
        </div>
      )}
    </>
  )
}
