import React from "react"
import { FaPen } from "react-icons/fa"
import { useNumberPlaceBoardContext } from "../context/NumberPlaceBoardContext"
import { Button } from "./Button"
import { classNames } from "../../src/utils/classNames"
import { useIntl } from "react-intl"

export const ToggleMemoButton = () => {
  const { toggleMemoMode, memoMode, solved, paused, handleOperation } = useNumberPlaceBoardContext()
  const intl = useIntl()

  return (
    <div className="space-y-1 md:space-y-2">
      <div className="relative">
        <Button
          disabled={solved || paused}
          onClick={() => {
            toggleMemoMode()
            handleOperation()
          }}
          className="w-full rounded-full"
          isActive={memoMode}
        >
          <FaPen className="inline-block w-6 h-6" />
        </Button>
        <div
          className={classNames(
            "absolute top-0 right-0 rounded-full px-2 -translate-y-1/3 translate-x-2 font-bold text-xs py-1 border-2 border-white text-white select-none",
            memoMode ? "bg-[#0072E2]" : "bg-gray-400",
            (solved || paused) && "opacity-30"
          )}
        >
          {memoMode ? "ON" : "OFF"}
        </div>
      </div>
      <div
        className={classNames(
          "text-xs font-bold text-[#0072E2] select-none",
          (solved || paused) && "opacity-30"
        )}
      >
        {intl.formatMessage({ id: "Notes" })}
      </div>
    </div>
  )
}
