import React from "react"
import { classNames } from "../../utils/classNames"

export const Label = ({ children, ...props }: JSX.IntrinsicElements["label"]) => {
  const baseClassName = "flex text-gray-700 font-bold mb-2"

  return (
    <label className={classNames(baseClassName)} {...props}>
      {children}
    </label>
  )
}
