import React from "react"
import { fetchApi } from "../src/lib/fetcher"
import { Formik, Form, Field, FieldArray, ErrorMessage, yupToFormErrors } from "formik"
import clone from "just-clone"

import dailyQuizValidationSchema from "../src/validations/dailyQuizValidationSchema"

import { Label } from "../src/components/form/Label"
import { TextField } from "../src/components/form/TextField"
import { TextAreaField } from "../src/components/form/TextAreaField"
import Button from "../src/components/Button"
import IconButton from "../src/components/IconButton"
import Thumb from "../src/components/Thumb"
import PlusIcon from "../src/components/icons/Plus"
import MinusIcon from "../src/components/icons/Minus"

const QUESTION_MAX_LENGTH = 50
const CHOICE_MAX_LENGTH = 50

type Choice = {
  id?: number
  choice_text: string
  choice_image: any
  is_correct_answer: boolean
}

type Question = {
  id?: number
  title: string
  top_image: null
  remove_top_image: false
  display_type: "text_type" | "image_type"
  kaisetsu_text: string
  daily_quiz_question_choices: Choice[]
}

const getInitialQuestionObj = (): Question => ({
  id: null,
  title: "",
  top_image: null,
  remove_top_image: false,
  display_type: "text_type",
  kaisetsu_text: "",
  daily_quiz_question_choices: [clone(initialChoiceObj), clone(initialChoiceObj)],
})

const initialChoiceObj = {
  id: null,
  choice_text: "",
  choice_image: null,
  is_correct_answer: false,
}

export default function DailyQuizForm({
  authenticity_token,
  daily_quiz_list_hash_id,
  daily_quiz,
  is_new_record,
  send_method,
  send_path,
}: {
  authenticity_token: string
  daily_quiz_list_hash_id: string
  daily_quiz: any
  is_new_record: boolean
  send_method: string
  send_path: string
}) {
  return (
    <>
      <Formik
        initialValues={
          is_new_record
            ? {
                day_num: 1,
                publish_date: "2022-08-01",
                daily_quiz_questions: [getInitialQuestionObj(), getInitialQuestionObj()],
                general: null,
              }
            : {
                day_num: daily_quiz.day_num,
                publish_date: daily_quiz.publish_date,
                daily_quiz_questions: daily_quiz.daily_quiz_questions.map((question) => ({
                  id: question.id,
                  title: question.title,
                  top_image: null,
                  remove_top_image: false,
                  display_type: question.display_type,
                  kaisetsu_text: question.kaisetsu_text,
                  daily_quiz_question_choices: question.daily_quiz_question_choices.map(
                    (choice) => ({
                      id: choice.id,
                      choice_text: choice.choice_text,
                      choice_image: null,
                      is_correct_answer: choice.is_correct_answer,
                    })
                  ),
                })),
                general: null,
              }
        }
        validationSchema={dailyQuizValidationSchema}
        onSubmit={async (values, actions) => {
          const { day_num, publish_date, daily_quiz_questions } = values

          const data = new FormData()
          data.append("utf8", "✓")
          data.append("format", "json")
          data.append("authenticity_token", authenticity_token)
          data.append("daily_quiz[day_num]", day_num)
          data.append("daily_quiz[publish_date]", publish_date)

          daily_quiz_questions.forEach((question, questionIndex) => {
            const questionParamName = `daily_quiz[daily_quiz_questions_attributes][${questionIndex}]`
            data.append(`${questionParamName}[order_num]`, questionIndex.toString())
            if (question.id) {
              data.append(`${questionParamName}[id]`, question.id.toString())
            }
            data.append(`${questionParamName}[title]`, question.title)
            if (question.top_image) {
              data.append(`${questionParamName}[top_image]`, question.top_image)
            }
            data.append(
              `${questionParamName}[remove_top_image]`,
              question.remove_top_image ? "true" : "false"
            )
            data.append(`${questionParamName}[display_type]`, question.display_type)
            data.append(`${questionParamName}[kaisetsu_text]`, question.kaisetsu_text)

            question.daily_quiz_question_choices.forEach((choice, choiceIndex) => {
              const choiceParamName = `${questionParamName}[daily_quiz_question_choices_attributes][${choiceIndex}]`
              data.append(`${choiceParamName}[order_num]`, choiceIndex.toString())
              if (choice.id) {
                data.append(`${choiceParamName}[id]`, choice.id.toString())
              }
              data.append(`${choiceParamName}[choice_text]`, choice.choice_text)
              if (choice.choice_image) {
                data.append(`${choiceParamName}[choice_image]`, choice.choice_image)
              }
              data.append(
                `${choiceParamName}[is_correct_answer]`,
                choice.is_correct_answer ? "true" : "false"
              )
            })
          })

          try {
            const result = await fetchApi(send_path, send_method, data, {})
            location.pathname = `/mypage/daily-quiz-list/${daily_quiz_list_hash_id}`
          } catch (error) {
            console.log(error)
            if (error.data && error.data.name === "ValidationError") {
              const errors = yupToFormErrors(error.data.error)
              actions.setErrors({
                ...errors,
                general: "入力エラーがあります",
              })
            } else {
              actions.setFieldError("general", "エラーが発生しました")
            }
          } finally {
            actions.setSubmitting(false)
          }
        }}
      >
        {(formProps) => {
          const {
            initialValues,
            values,
            errors,
            status,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            setFieldTouched,
          } = formProps

          return (
            <Form>
              <div className="px-3 py-2 my-4 text-lg font-bold leading-none bg-gray-200">
                基本情報
              </div>

              <div className="mb-2">
                <Label htmlFor="day_num">日目</Label>
                <TextField type="number" name="day_num" placeholder="1" min={1} max={50} />
              </div>

              <div className="mb-2">
                <Label htmlFor="publish_date">公開日</Label>
                <TextField type="date" name="publish_date" />
              </div>

              <div className="px-3 py-2 my-4 text-lg font-bold leading-none bg-gray-200">設問</div>

              <FieldArray
                name={"daily_quiz_questions"}
                render={(arrayHelpersForQuestion) => (
                  <div className="">
                    {values.daily_quiz_questions.map((question, questionIndex) => (
                      <QuestionFields
                        key={`question_${questionIndex}`}
                        questionIndex={questionIndex}
                        formProps={formProps}
                        arrayHelpersForQuestion={arrayHelpersForQuestion}
                        daily_quiz={daily_quiz}
                      />
                    ))}
                    <div>
                      <Button
                        onClick={(e) => {
                          arrayHelpersForQuestion.push(getInitialQuestionObj())
                          e.currentTarget.blur()
                        }}
                        disabled={values.daily_quiz_questions.length >= QUESTION_MAX_LENGTH}
                        size="sm"
                        rounded={true}
                        fullwidth={undefined}
                        leftIcon={
                          <span className="pr-1">
                            <PlusIcon className="w-4 h-4" />
                          </span>
                        }
                        rightIcon={undefined}
                        loaderColor={undefined}
                      >
                        <span>設問を追加する</span>
                      </Button>
                    </div>
                  </div>
                )}
              />
              {errors.daily_quiz_questions && typeof errors.daily_quiz_questions === "string" && (
                <div className="text-xs text-center text-red-500">
                  {errors.daily_quiz_questions}
                </div>
              )}

              <div className="mb-8 text-center">
                <Button
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                  loading={isSubmitting}
                  rounded={true}
                  color="blue"
                  size={undefined}
                  fullwidth={undefined}
                  leftIcon={undefined}
                  rightIcon={undefined}
                  loaderColor={undefined}
                >
                  投稿する
                </Button>
                {errors.general && (
                  <div className="mt-4 text-center text-red-500">{errors.general}</div>
                )}
              </div>
            </Form>
          )
        }}
      </Formik>
    </>
  )
}

const QuestionFields = ({ questionIndex, formProps, arrayHelpersForQuestion, daily_quiz }) => {
  const {
    initialValues,
    values,
    errors,
    status,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    setFieldTouched,
  } = formProps

  return (
    <div className="relative px-4 pb-4 mb-4 overflow-hidden border border-gray-800 rounded">
      <div className="px-4 py-1.5 mb-4 -mx-4 font-bold text-white bg-gray-800">
        設問 {questionIndex + 1}
      </div>
      <div className="absolute top-0 right-0 p-1.5">
        <IconButton
          disabled={
            values.daily_quiz_questions.length <= 2 || values.daily_quiz_questions[questionIndex].id
          }
          onClick={(e) => {
            arrayHelpersForQuestion.remove(questionIndex)
            e.currentTarget.blur()
          }}
          rounded={true}
          color="white-focus-red"
          colorClassName={undefined}
        >
          <span className="p-0.5">
            <MinusIcon className="w-4 h-4" />
          </span>
        </IconButton>
      </div>

      <div className="mb-2">
        <Label htmlFor={`daily_quiz_questions[${questionIndex}].title`}>設問テキスト</Label>
        <TextAreaField
          name={`daily_quiz_questions[${questionIndex}].title`}
          placeholder={`設問 ${questionIndex + 1}`}
        />
      </div>

      <div className="mb-4">
        <Label htmlFor={`daily_quiz_questions[${questionIndex}].top_image`}>設問画像 (任意)</Label>
        <Thumb
          initialImageUrl={daily_quiz?.daily_quiz_questions?.[questionIndex]?.top_image?.url}
          file={values.daily_quiz_questions[questionIndex].top_image}
        />
        <input
          name={`daily_quiz_questions[${questionIndex}].top_image`}
          type="file"
          accept="image/*"
          onChange={(event) => {
            setFieldValue(
              `daily_quiz_questions[${questionIndex}].top_image`,
              event.currentTarget.files[0]
            )
          }}
        />
        <label>
          <Field type="checkbox" name={`daily_quiz_questions[${questionIndex}].remove_top_image`} />{" "}
          削除する
        </label>
        {touched.daily_quiz_questions?.[questionIndex]?.top_image &&
          errors.daily_quiz_questions?.[questionIndex]?.top_image && (
            <div className="mt-1 text-xs text-red-500">
              {errors.daily_quiz_questions[questionIndex].top_image}
            </div>
          )}
      </div>

      <div className="mb-2">
        <Label>選択肢の形式</Label>
        <div className="flex items-center space-x-4">
          <label className="flex items-center cursor-pointer">
            <Field
              type="radio"
              name={`daily_quiz_questions[${questionIndex}].display_type`}
              value={"text_type"}
            />
            <span className="ml-2">テキスト</span>
          </label>
          <label className="flex items-center cursor-pointer">
            <Field
              type="radio"
              name={`daily_quiz_questions[${questionIndex}].display_type`}
              value={"image_type"}
            />
            <span className="ml-2">画像をアップロード</span>
          </label>
        </div>
      </div>

      <hr className="my-4" />

      <FieldArray
        name={`daily_quiz_questions[${questionIndex}].daily_quiz_question_choices`}
        render={(arrayHelpersForChoice) => (
          <div className="">
            {values.daily_quiz_questions[questionIndex].daily_quiz_question_choices.map(
              (choice, choiceIndex) => (
                <ChoiceFields
                  key={`question_${questionIndex}_choice_${choiceIndex}`}
                  questionIndex={questionIndex}
                  choiceIndex={choiceIndex}
                  formProps={formProps}
                  arrayHelpersForChoice={arrayHelpersForChoice}
                  daily_quiz={daily_quiz}
                />
              )
            )}
            <div>
              <Button
                onClick={(e) => {
                  arrayHelpersForChoice.push(clone(initialChoiceObj))
                  e.currentTarget.blur()
                }}
                size="sm"
                rounded={true}
                disabled={
                  values.daily_quiz_questions[questionIndex].daily_quiz_question_choices.length >=
                  CHOICE_MAX_LENGTH
                }
                leftIcon={
                  <span className="pr-1">
                    <PlusIcon className="w-4 h-4" />
                  </span>
                }
                fullwidth={undefined}
                rightIcon={undefined}
                loaderColor={undefined}
              >
                <span>選択肢を追加する</span>
              </Button>
            </div>
          </div>
        )}
      />
      {errors.daily_quiz_questions && typeof errors.daily_quiz_questions === "string" && (
        <div className="text-xs text-center text-red-500">{errors.daily_quiz_questions}</div>
      )}

      <hr className="my-4" />

      <div className="mb-2">
        <Label>解説文 (任意：解答時に正解・不正解の判定と一緒に表示されます)</Label>
        <TextAreaField name={`daily_quiz_questions[${questionIndex}].kaisetsu_text`} max={1000} />
      </div>
    </div>
  )
}

const ChoiceFields = ({
  questionIndex,
  choiceIndex,
  formProps,
  arrayHelpersForChoice,
  daily_quiz,
}) => {
  const {
    initialValues,
    values,
    errors,
    status,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    setFieldTouched,
  } = formProps

  return (
    <div className="mb-4 border rounded">
      <div className="flex items-center justify-between px-4 py-1 bg-gray-100">
        <div>選択肢{choiceIndex + 1}</div>
        <div className="flex items-center">
          <IconButton
            disabled={
              values.daily_quiz_questions[questionIndex].daily_quiz_question_choices.length <= 2 ||
              values.daily_quiz_questions[questionIndex].daily_quiz_question_choices[choiceIndex].id
            }
            onClick={(e) => {
              arrayHelpersForChoice.remove(choiceIndex)
              e.currentTarget.blur()
            }}
            rounded={true}
            color="white-focus-red"
            colorClassName={undefined}
          >
            <span className="p-0.5">
              <MinusIcon className="w-4 h-4" />
            </span>
          </IconButton>
        </div>
      </div>
      <div className="p-4">
        <div className="mb-2">
          <Label>選択肢テキスト</Label>
          <TextField
            type="text"
            name={`daily_quiz_questions[${questionIndex}].daily_quiz_question_choices[${choiceIndex}].choice_text`}
            placeholder={`選択肢 ${choiceIndex + 1}`}
            max={25}
          />
        </div>

        {values.daily_quiz_questions[questionIndex].display_type === "image_type" && (
          <div className="mb-4">
            <Label>選択肢画像 (省略時は画像形式のテキストを表示)</Label>
            <Thumb
              initialImageUrl={
                daily_quiz?.daily_quiz_questions?.[questionIndex]?.daily_quiz_question_choices?.[
                  choiceIndex
                ]?.choice_image?.url
              }
              file={
                values.daily_quiz_questions[questionIndex].daily_quiz_question_choices[choiceIndex]
                  .choice_image
              }
            />
            <input
              name={`daily_quiz_questions[${questionIndex}].daily_quiz_question_choices[${choiceIndex}].choice_image`}
              type="file"
              accept="image/*"
              onChange={(event) => {
                setFieldValue(
                  `daily_quiz_questions[${questionIndex}].daily_quiz_question_choices[${choiceIndex}].choice_image`,
                  event.currentTarget.files[0]
                )
              }}
            />
            {touched.daily_quiz_questions?.[questionIndex]?.daily_quiz_question_choices?.[
              choiceIndex
            ]?.choice_image &&
              errors.daily_quiz_questions?.[questionIndex]?.daily_quiz_question_choices?.[
                choiceIndex
              ]?.choice_image && (
                <div className="mt-1 text-xs text-red-500">
                  {
                    errors.daily_quiz_questions[questionIndex].daily_quiz_question_choices[
                      choiceIndex
                    ].choice_image
                  }
                </div>
              )}
          </div>
        )}

        <div className="">
          <label>
            <Field
              type="checkbox"
              name={`daily_quiz_questions[${questionIndex}].daily_quiz_question_choices[${choiceIndex}].is_correct_answer`}
              onChange={() => {
                // 他の選択肢を全てfalseにする
                values.daily_quiz_questions[questionIndex].daily_quiz_question_choices.forEach(
                  (choice, i) => {
                    if (i !== choiceIndex) {
                      setFieldValue(
                        `daily_quiz_questions[${questionIndex}].daily_quiz_question_choices[${i}].is_correct_answer`,
                        false
                      )
                    }
                  }
                )
                setFieldValue(
                  `daily_quiz_questions[${questionIndex}].daily_quiz_question_choices[${choiceIndex}].is_correct_answer`,
                  !values.daily_quiz_questions[questionIndex].daily_quiz_question_choices[
                    choiceIndex
                  ].is_correct_answer
                )
              }}
            />{" "}
            この選択肢を正解にする
          </label>
        </div>
      </div>
    </div>
  )
}
