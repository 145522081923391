import React, { useEffect, useState } from "react"
import firebase, { firebaseError } from "../src/lib/firebase"
import { fetchJson } from "../src/lib/fetcher"
import { useFirebaseUser } from "../src/lib/firebase"
import LoaderContainer from "../src/components/LoaderContainer"
import { LoginButtonPanel } from "../src/components/LoginButtonPanel"

export const loginOrSignUp = async (idToken, isNewUser, bodyParams) => {
  const path = isNewUser ? "/signup" : "/login"
  const csrfToken = document.head.querySelector("meta[name='csrf-token']").getAttribute("content")
  const headers = {
    "Content-Type": "application/json",
    "X-CSRF-TOKEN": csrfToken,
    Authorization: `Bearer ${idToken}`,
  }
  try {
    await fetchJson(path, {
      method: "POST",
      credentials: "include",
      mode: "cors",
      headers,
      body: bodyParams,
    })

    const searchParams = new URLSearchParams(window.location.search)
    const nextUrl = searchParams && searchParams.get("next")

    if (isNewUser) {
      // トースト表示 TODO
      // dispatch({ type: "ADD_TOAST", payload: { id: Math.random(), type: 'success', text: '登録が完了しました' } })

      // プロフィール設定画面にリダイレクト (new=1 をつける)
      window.location.href = nextUrl
        ? `/setting/profile?new=1&next=${encodeURIComponent(nextUrl)}`
        : "/setting/profile?new=1"
    } else {
      // トースト表示 TODO
      // dispatch({ type: "ADD_TOAST", payload: { id: Math.random(), type: 'success', text: 'ログインしました' } })

      // リダイレクト
      window.location.href = nextUrl || "/"
    }
  } catch (e) {
    console.error(e.message)
    console.error(e.data.message)
  }
}

export const useLogin = () => {
  const [loginLoading, setLoginLoading] = useState(false)

  useEffect(() => {
    firebase
      .auth()
      .getRedirectResult()
      .then((result) => {
        if (result.user) {
          setLoginLoading(true)

          const bodyParams = result.credential
            ? JSON.stringify({
                credential: result.credential,
                providerData: result.user.providerData,
              })
            : null

          const isNewUser = result.additionalUserInfo.isNewUser

          result.user
            .getIdToken(true)
            .then((idToken) => {
              loginOrSignUp(idToken, isNewUser, bodyParams)
            })
            .catch((error) => {
              setLoginLoading(false)
              console.error(`Firebase getIdToken failed!: ${error.message}`)
            })
        }
      })
      .catch((error) => {
        setLoginLoading(false)
        console.error(error)
        window.alert(`ログインに失敗しました\n${firebaseError(error)}`)
      })
  }, [])

  return { loginLoading }
}

export default function Login() {
  const { loginLoading } = useLogin()
  const [user, loadingUser, userError] = useFirebaseUser()

  // ローディング中
  if (loadingUser || loginLoading) {
    return <LoaderContainer />
  }

  return (
    <div className="container max-w-sm px-4 mx-auto">
      <div className="pt-8 pb-4 text-center">
        <h1 className="text-2xl font-semibold leading-relaxed">ログイン</h1>
      </div>
      <div className="mb-4 text-xs text-center">許可なくツイート等は行われません。</div>

      <LoginButtonPanel />
    </div>
  )
}
