import React from "react"

const Label = ({children, isNarrow, horizontalFrom, htmlFor}) => {
  const baseClassName = 'block text-gray-700 font-bold'

  const mtClassName = isNarrow ? 'mt-0' : 'mb-1'

  let horizontalClassName = ''
  if (horizontalFrom === 'sm') {
    horizontalClassName = 'sm:text-right sm:mt-2 sm:mb-0 sm:pr-4'
  }

  return <label className={`${baseClassName} ${mtClassName} ${horizontalClassName}`} htmlFor={htmlFor}>{children}</label>
}
export default Label
