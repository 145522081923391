import React from "react"
import { useNumberPlaceBoardContext } from "../context/NumberPlaceBoardContext"
import { classNames } from "../../src/utils/classNames"
import { FaPause, FaPlay } from "react-icons/fa"

export const StopwatchButton = () => {
  const { displayTime, solved, paused, stopwatchStart, stopwatchPause } =
    useNumberPlaceBoardContext()

  return (
    <button
      disabled={solved}
      onClick={() => {
        paused ? stopwatchStart() : stopwatchPause()
      }}
      className="group inline-flex pl-4 pr-1.5 py-1 rounded-full space-x-2 bg-[#EAEEF3] disabled:pointer-events-none hover:bg-[#DBE3ED] active:bg-[#D1DAE7]"
    >
      <div className="font-mono text-gray-800 whitespace-nowrap">{displayTime}</div>
      <div
        className={classNames("p-1.5 rounded-full bg-white text-[#0072E2]", solved && "opacity-30")}
      >
        {paused ? <FaPlay className="w-2 h-2" /> : <FaPause className="w-2 h-2" />}
      </div>
    </button>
  )
}
