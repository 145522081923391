import React, { useState, useEffect } from "react"
import { useFormik } from "formik"
import { fetchApi } from "../src/lib/fetcher"
import { classNames } from "../src/utils/classNames"
import Countdown from "react-countdown"
import { createPortal } from "react-dom"

const countdownRoot = document.getElementById("react-countdown-root")

type DailyQuiz = {
  hash_id: string
}

type DailyQuizQuestion = {
  hash_id: string
}

export default function DailyQuiz({
  dailyQuiz,
  dailyQuizQuestion,
}: {
  dailyQuiz: DailyQuiz
  dailyQuizQuestion: DailyQuizQuestion
}) {
  const initialSeconds = 10
  return (
    <Countdown
      date={Date.now() + initialSeconds * 1000}
      intervalDelay={0}
      autoStart={true}
      renderer={({ seconds, milliseconds, completed, api }) => {
        return (
          <>
            <div>
              <CountdownAnimation
                seconds={milliseconds > 0 ? seconds + 1 : seconds}
                initialSeconds={initialSeconds}
                onClick={() => {
                  if (!completed) {
                    if (api.isStopped() || api.isPaused()) {
                      api.start()
                    } else {
                      api.pause()
                    }
                  }
                }}
              />
            </div>

            <SelectAnswerForm
              dailyQuiz={dailyQuiz}
              dailyQuizQuestion={dailyQuizQuestion}
              timedOut={completed}
              onBeforeSubmit={() => {
                api.pause()
              }}
            />
          </>
        )
      }}
    />
  )
}

const SelectAnswerForm = ({
  dailyQuiz,
  dailyQuizQuestion,
  timedOut,
  onBeforeSubmit,
}: {
  dailyQuiz: DailyQuiz
  dailyQuizQuestion: DailyQuizQuestion
  timedOut: boolean
  onBeforeSubmit: () => void
}) => {
  const formik = useFormik({
    initialValues: { choice_hash_id: null, general: undefined },
    onSubmit: async (values, actions) => {
      onBeforeSubmit()

      const { choice_hash_id } = values
      try {
        const postedData = await fetchApi(
          `/daily-quiz/${dailyQuiz.hash_id}/${dailyQuizQuestion.hash_id}/answer`,
          "POST",
          JSON.stringify({ choice_hash_id })
        )

        // GAイベント(投票)
        //window.dataLayer = window.dataLayer || []
        //window.dataLayer.push({
        //  event: "vote",
        //  event_params: {
        //    action: "vote",
        //    label: "to_quiz_topic",
        //  },
        //})

        // リダイレクト
        if (choice_hash_id) {
          window.location.href = `/daily-quiz/${dailyQuiz.hash_id}/${dailyQuizQuestion.hash_id}/${choice_hash_id}`
        } else {
          window.location.href = `/daily-quiz/${dailyQuiz.hash_id}/${dailyQuizQuestion.hash_id}/timeup`
        }
      } catch (error) {
        console.error(error.message)
        if (error.data && error.data.message) {
          actions.setErrors({
            general: error.data.message || "入力エラーがあります",
          })
        } else {
          actions.setFieldError("general", "エラーが発生しました")
        }
      } finally {
        actions.setSubmitting(false)
      }
    },
  })

  const {
    values,
    errors,
    status,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    setFieldTouched,
    submitForm,
  } = formik

  useEffect(() => {
    if (timedOut) {
      console.log("timed out!")
      // 選択肢ボタンを取得
      const $choiceButtons = Array.prototype.slice.call(
        document.querySelectorAll('[data-choice-button="true"]'),
        0
      )
      // 全ての選択肢を disabled にする
      $choiceButtons.forEach(($choiceButton) => {
        $choiceButton.disabled = true
      })
      // フォーム送信
      submitForm()
    }
  }, [timedOut])

  const setAndSubmit = async (choiceHashId) => {
    // 値をセットしてフォーム送信
    setFieldValue("choice_hash_id", choiceHashId)
    await Promise.resolve()
    submitForm()
  }

  useEffect(() => {
    // 選択肢ボタンを取得
    const $choiceButtons = Array.prototype.slice.call(
      document.querySelectorAll('[data-choice-button="true"]'),
      0
    )
    $choiceButtons.forEach(($choiceButton) => {
      $choiceButton.addEventListener("click", function (e) {
        // クリックされた選択肢のハッシュIDを取得
        const choiceHashId = $choiceButton.dataset.choiceHashId
        // 全ての選択肢を disabled にする
        $choiceButtons.forEach(($choiceButton2) => {
          $choiceButton2.disabled = true
        })
        // 選択したボタンを半透明にする
        $choiceButton.classList.add("opacity-50")
        // フォーム送信
        setAndSubmit(choiceHashId)
      })
    })
  }, [])

  return errors.general ? (
    <div className="mt-4 text-center text-red-500">{errors.general}</div>
  ) : null
}

const CountdownAnimation = ({
  seconds,
  initialSeconds,
  onClick,
}: {
  seconds: number
  initialSeconds: number
  onClick: () => void
}) => {
  const [styleStatus, setStyleStatus] = useState<0 | 1 | 2>()

  const isInitialSeconds = seconds === initialSeconds

  // 秒数が変わったら styleStatus を true に
  useEffect(() => {
    setStyleStatus(0)
  }, [seconds])

  // styleStatus が true になったら false に
  useEffect(() => {
    if (!isInitialSeconds && styleStatus === 0) {
      setTimeout(() => {
        setStyleStatus(1)
      }, 100)
      setTimeout(() => {
        setStyleStatus(2)
      }, 200)
    }
  }, [isInitialSeconds, styleStatus])

  const className = classNames(
    isInitialSeconds || (styleStatus === 0 && seconds === initialSeconds - 1)
      ? ""
      : styleStatus === 0
      ? "scale-0"
      : styleStatus === 1 || seconds === 0
      ? "transition-transform duration-100 scale-125"
      : "transition-transform duration duration-[0.8s] scale-0",
    seconds <= 3 && "text-red-500"
  )

  return createPortal(
    <div className={className} onClick={onClick}>
      <div>{isInitialSeconds ? seconds - 1 : seconds}</div>
    </div>,
    countdownRoot
  )
}
