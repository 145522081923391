import React from "react"
import { FaUndo } from "react-icons/fa"
import { classNames } from "../../src/utils/classNames"
import { useNumberPlaceBoardContext } from "../context/NumberPlaceBoardContext"
import { Button } from "./Button"
import { useIntl } from "react-intl"

export const UndoButton = () => {
  const { undo, solved, paused, handleOperation } = useNumberPlaceBoardContext()
  const intl = useIntl()

  return (
    <div className="space-y-1 md:space-y-2">
      <div>
        <Button
          disabled={solved || paused}
          onClick={() => {
            undo()
            handleOperation()
          }}
          className="w-full rounded-full"
        >
          <span>
            <FaUndo className="inline-block w-6 h-6" />
          </span>
        </Button>
      </div>
      <div
        className={classNames(
          "text-xs font-bold text-[#0072E2]",
          (solved || paused) && "opacity-30"
        )}
      >
        {intl.formatMessage({ id: "Undo" })}
      </div>
    </div>
  )
}
