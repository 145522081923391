import React, { useState } from "react"
import { RegisterEmailForm } from "../src/components/RegisterEmailForm"

export default function LpRegisterEmailFormApp() {
  const [registered, setRegisterd] = useState(false)

  return (
    <div className="p-6 bg-white border-8">
      {registered ? (
        <div className="text-lg font-bold text-center">登録が完了しました！</div>
      ) : (
        <>
          <div className="text-lg font-bold">当選連絡のメールアドレスはここから登録を！</div>
          <div className="mt-4">
            <RegisterEmailForm
              buttonImageSrc="/images/buttons/tourokusuru.png"
              handleSubmitSucceeded={() => {
                // GAイベント(メールアドレス登録)
                //window.dataLayer = window.dataLayer || []
                //window.dataLayer.push({
                //  event: "register_email",
                //  event_params: {
                //    action: "register_email",
                //    label: "to_lp",
                //  },
                //})
                setRegisterd(true)
              }}
            />
            <div className="mt-4 text-xs font-bold text-center ">
              クイズキャッスルからのご案内以外には
              <br />
              利用いたしません。
            </div>
          </div>
        </>
      )}
    </div>
  )
}
