import React, { ButtonHTMLAttributes } from "react"
import { classNames } from "../../src/utils/classNames"

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  isActive?: boolean
}
export const Button = ({ isActive, ...props }: ButtonProps) => {
  return (
    <button
      {...props}
      className={classNames(
        "relative py-2 md:py-4 rounded-md transition-colors font-semibold",
        isActive
          ? "bg-[#0072E2] hover:bg-[#0065c8] active:bg-[#3a90e5] text-white"
          : "bg-[#EAEEF3] hover:bg-[#DBE3ED] active:bg-[#D1DAE7] text-[#0072E2]",
        props.className,
        props.disabled && "opacity-30 pointer-events-none"
      )}
    />
  )
}
