import React from "react"

export default function Button({
  children,
  type = "button",
  rounded,
  colorClassName,
  color = "light",
  ...props
}) {
  // 基本
  let buttonClassName = `
    relative inline-flex justify-center items-center
    select-none cursor-pointer
    focus:outline-none
    disabled:cursor-not-allowed`

  if (colorClassName) {
    buttonClassName += " " + colorClassName
  } else if (color === "light-focus-red") {
    buttonClassName += `
      bg-gray-100 text-gray-800 border-transparent
      hover:bg-red-500 hover:text-white touch-hover:bg-gray-100 touch-hover:text-gray-800
      focus:bg-red-500 focus:text-white focus:shadow-outline-red
      disabled:opacity-50 disabled:bg-gray-100 disabled:text-gray-800
    `
  } else {
    buttonClassName += `
      bg-gray-100 text-gray-800 border-transparent
      hover:bg-gray-100
      focus:shadow-outline-gray
      disabled:opacity-50 disabled:bg-gray-100
    `
  }

  buttonClassName += " " + (rounded ? "rounded-full" : "rounded")

  buttonClassName = buttonClassName.replace(/\s+/g, " ")

  return (
    <button type={type} {...props} className={buttonClassName}>
      {children}
    </button>
  )
}
