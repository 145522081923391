import React, { ButtonHTMLAttributes } from "react"
import { classNames } from "../../utils/classNames"

type ButtonProps = {
  loading?: boolean
  color?: "blue" | "light"
} & ButtonHTMLAttributes<any>

const colorClassNames = {
  blue: "bg-blue-500 text-white border-transparent hover:bg-blue-600 touch-hover:bg-blue-500 focus:shadow-outline-blue disabled:opacity-50 disabled:bg-blue-500",
  light:
    "bg-gray-100 text-gray-800 border-transparent hover:bg-gray-100 touch-hover:bg-gray-100 focus:shadow-outline-gray disabled:opacity-50 disabled:bg-gray-100",
}

export const Button: React.FC<ButtonProps> = ({
  children,
  loading = false,
  color = "blue",
  ...props
}) => {
  const buttonClassName =
    "relative inline-flex justify-center items-center border select-none cursor-pointer group focus:outline-none disabled:cursor-not-allowed rounded py-1.5 px-2 whitespace-nowrap"
  const colorClassName = colorClassNames[color]

  return (
    <button
      type="button"
      className={classNames(buttonClassName, colorClassName, loading && "!text-transparent")}
      {...props}
    >
      {loading && (
        <svg className="absolute w-4 h-4 animate-spin-fast" viewBox="0 0 32 32">
          <circle
            cx="16"
            cy="16"
            fill="none"
            r="14"
            strokeWidth="4"
            stroke="white"
            strokeDasharray="100"
            strokeDashoffset="60"
          ></circle>
        </svg>
      )}
      {children}
    </button>
  )
}
