import { object, string, array, number, bool, mixed, date } from "yup"

export default object().shape({
  day_num: number()
    .required("入力してください")
    .typeError("不正な値です")
    .min(1, "1以上で入力してください")
    .max(10, "10以下で入力してください"),
  publish_date: date().required("入力してください"),
  daily_quiz_questions: array()
    .of(
      object().shape({
        id: number().nullable(),
        title: string()
          .typeError("不正な値です")
          .required("入力してください")
          .max(1000, "1000文字以内で入力してください"), // TODO 文字数制限
        top_image: mixed(),
        remove_top_image: bool(),
        display_type: string().typeError("不正な値です"),
        daily_quiz_question_choices: array()
          .of(
            object().shape({
              id: number().nullable(),
              choice_text: string()
                .typeError("不正な値です")
                .required("入力してください")
                .max(1000, "1000文字以内で入力してください"), // TODO 文字数制限
              choice_image: mixed(),
              is_correct_answer: bool(),
            })
          )
          .min(2, "選択肢は2つ以上登録してください")
          .max(50, "選択肢は50以下で登録してください")
          .test(
            "test-name",
            "「この選択肢を正解にする」に1つだけチェックしてください",
            (choices) => {
              return choices.filter((choice) => choice.is_correct_answer).length === 1
            }
          ),
      })
    )
    .typeError("不正な値です")
    .min(2, "設問は2つ以上登録してください")
    .max(50, "設問は50以下で登録してください"),
})
