import React, { Fragment, useState } from "react"
import { MenuIcon, UserIcon } from "@heroicons/react/solid"
import { AccountLinkCodeForm } from "../../src/components/AccountLinkCodeForm"
import { Modal } from "../../src/components/Modal"
import { motion } from "framer-motion/dist/framer-motion"
import { AccountLinkForm } from "../../src/components/AccountLinkForm"
import { useNumberPlaceBoardContext } from "../context/NumberPlaceBoardContext"
import useSWR from "swr"
import { fetchApi } from "../../src/lib/fetcher"
import { classNames } from "../../src/utils/classNames"
import { AccountLinkCodeShareForm } from "../../src/components/AccountLinkCodeShareForm"

export const DropdownMenu = ({ isNarrow = false }: { isNarrow: boolean }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { stopwatchStart, stopwatchPause } = useNumberPlaceBoardContext()
  const ref = React.createRef<HTMLDivElement>()

  const { data, error } = useSWR("/current_user", fetchApi)

  const openModal = () => {
    stopwatchPause()
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
    stopwatchStart()
  }

  return (
    <div className="relative inline-block text-left">
      <button
        className="inline-flex items-center justify-center p-1.5 rounded-md hover:bg-gray-100"
        disabled={!data}
        onClick={openModal}
      >
        <MenuIcon className="w-5 h-5" aria-hidden="true" />
      </button>
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        containerClassNames="fixed inset-0 z-50 px-4 overflow-y-auto"
        overlayClassNames=""
        useDefaultContainer={false}
        initialFocus={ref}
      >
        <div
          className={classNames(
            "relative flex items-center justify-end mx-auto space-x-2 mt-11 md:mt-12",
            isNarrow ? "max-w-screen-sm" : "max-w-md md:max-w-screen-lg"
          )}
        >
          <motion.div
            ref={ref}
            className="-mt-0.5 overflow-hidden origin-top-right bg-white border divide-y rounded-md shadow-md max-w-sm w-full"
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.95 }}
          >
            <div className="flex items-center p-4 space-x-3">
              <div>
                {data?.currentUser?.avatar_image ? (
                  <img src={data.currentUser.avatar_image} className="rounded-full w-7 h-7" />
                ) : (
                  <div className="flex items-center justify-center text-gray-500 bg-gray-100 rounded-full w-7 h-7">
                    <UserIcon className="w-4 h-4" />
                  </div>
                )}
              </div>
              <div className="text-sm truncate">
                {data?.currentUser?.display_name || "匿名"}さん
              </div>
            </div>
            {data && !data.currentUser && (
              <>
                <div className="p-4">
                  <AccountLinkForm />
                </div>
                <div className="p-4">
                  <AccountLinkCodeForm title="他のブラウザにデータを連携" />
                  <AccountLinkCodeShareForm />
                </div>
              </>
            )}
          </motion.div>
        </div>
      </Modal>
    </div>
  )
}
