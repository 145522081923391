import React, { useState, useEffect } from "react"

import { classNames } from "../src/utils/classNames"

export default function DailyQuizAnswerNextButton({
  isLastQuestion,
  nextPath,
}: {
  isLastQuestion: boolean
  nextPath: string
}) {
  const [timerStarted, setTimerStarted] = useState(false)

  const handleTimeout = () => {
    window.location.href = nextPath
  }

  useEffect(() => {
    setTimerStarted(true)
    const timer = setTimeout(() => {
      //some action
      console.log("6 seconds has passed. TimerID " + String(timer) + " has finished.")
      handleTimeout()
    }, 6 * 1000)
    console.log("TimerID " + String(timer) + " has started.")

    //クリーンアップ
    return () => {
      console.log("Restart button was clicked. TimerID " + String(timer) + " was canceled.")
      clearTimeout(timer)
    }
  }, [])

  return (
    <>
      <div className="flex items-center mb-6">
        <div className="flex items-center justify-center bg-white rounded-full w-7 h-7">
          <img src="/images/icons/time.png" width="52" height="52" className="w-5 h-5" />
        </div>
        <div className="relative flex-1 -ml-1 border-4 border-white">
          <div className="h-2 bg-gray-500" />
          <div
            className={classNames(
              "absolute top-0 right-0 h-2 bg-qc-blue",
              timerStarted ? "w-0" : "w-full"
            )}
            style={{ transition: "width 6s linear" }}
          />
        </div>
      </div>

      <div>
        <div className="flex mb-6">
          <div className="flex items-center w-1/2"></div>
          <div className="flex items-center justify-end w-1/2">
            <a
              href={nextPath}
              className="flex items-start h-auto w-4/5 max-w-[258px]"
              onClick={() => {
                // GA
                //window.dataLayer = window.dataLayer || []
                //window.dataLayer.push({
                //  event: "link-click",
                //  event_params: {
                //    action: "click_next_topic_link",
                //    label: "to_quiz_topic",
                //  },
                //})
              }}
            >
              <img
                src={
                  isLastQuestion
                    ? "/images/buttons/show-result.png"
                    : "/images/buttons/next-quiz.png?v=2"
                }
                width="258"
                height="100"
                className="w-full h-auto"
              />
            </a>
          </div>
        </div>
      </div>
    </>
  )
}
