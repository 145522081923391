import React from "react"
import { fetchApi } from "../src/lib/fetcher"
import { Formik, Field, FieldArray, ErrorMessage, yupToFormErrors } from "formik"

import dailyQuizListCreateValidationSchema from "../src/validations/dailyQuizListValidationSchema"

import { Label } from "../src/components/form/Label"
import { TextField } from "../src/components/form/TextField"
import Thumb from "../src/components/Thumb"
import { Button } from "../src/components/form/Button"

export default function DailyQuizListForm({
  authenticity_token,
  daily_quiz_list,
  is_new_record,
  send_method,
  send_path,
}) {
  return (
    <>
      <Formik
        initialValues={
          is_new_record
            ? {
                is_published: false,
                title: "",
                top_image: null,
                remove_top_image: false,
                general: null,
              }
            : {
                is_published: daily_quiz_list.is_published,
                title: daily_quiz_list.title,
                top_image: null,
                remove_top_image: false,
                general: null,
              }
        }
        validationSchema={dailyQuizListCreateValidationSchema}
        onSubmit={async (values, actions) => {
          const { is_published, title, top_image, remove_top_image } = values

          const data = new FormData()
          data.append("utf8", "✓")
          data.append("format", "json")
          data.append("authenticity_token", authenticity_token)
          data.append("daily_quiz_list[is_published]", is_published)
          data.append("daily_quiz_list[title]", title)
          if (top_image) {
            data.append("daily_quiz_list[top_image]", top_image)
          }
          data.append("daily_quiz_list[remove_top_image]", remove_top_image ? "true" : "false")

          try {
            const result = await fetchApi(send_path, send_method, data, {})
            location.pathname = `/mypage/daily-quiz-list/${result.daily_quiz_list.hash_id}`
          } catch (error) {
            console.log(error)
            if (error.data && error.data.name === "ValidationError") {
              const errors = yupToFormErrors(error.data.error)
              actions.setErrors({
                ...errors,
                general: "入力エラーがあります",
              })
            } else {
              actions.setFieldError("general", "エラーが発生しました")
            }
          } finally {
            actions.setSubmitting(false)
          }
        }}
      >
        {(formProps) => {
          const {
            initialValues,
            values,
            errors,
            status,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            setFieldTouched,
          } = formProps

          return (
            <form onSubmit={handleSubmit}>
              <div className="mb-2">
                <Label htmlFor="title">タイトル</Label>
                <TextField type="text" name="title" placeholder="タイトル" max={50} />
              </div>

              <div className="mb-2">
                <Label>公開状態</Label>
                <div>
                  <label>
                    <Field
                      type="radio"
                      name="is_published"
                      value={true}
                      onChange={() => setFieldValue("is_published", true)}
                    />
                    公開
                  </label>
                  <label>
                    <Field
                      type="radio"
                      name="is_published"
                      value={false}
                      onChange={() => setFieldValue("is_published", false)}
                    />
                    非公開
                  </label>
                </div>
              </div>

              <div className="mb-4">
                <Label htmlFor="top_image">トップ画像 (任意)</Label>
                <Thumb initialImageUrl={daily_quiz_list?.top_image?.url} file={values.top_image} />
                <input
                  id="top_image"
                  name="top_image"
                  type="file"
                  accept="image/*"
                  onChange={(event) => {
                    setFieldValue("top_image", event.currentTarget.files[0])
                  }}
                />
                <label>
                  <Field type="checkbox" name="remove_top_image" /> 削除する
                </label>
                {touched.top_image && errors.top_image && (
                  <div className="text-xs text-red-500">{errors.top_image}</div>
                )}
              </div>

              <div className="mb-8">
                <Button
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                  loading={isSubmitting}
                  color="blue"
                >
                  保存する
                </Button>
                {errors.general && (
                  <div className="mt-4 text-center text-red-500">{errors.general}</div>
                )}
              </div>
            </form>
          )
        }}
      </Formik>
    </>
  )
}
