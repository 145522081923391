import React, { useState, useEffect } from "react"
import { useFormik } from "formik"
import { fetchApi } from "../src/lib/fetcher"
import { classNames } from "../src/utils/classNames"
import Countdown from "react-countdown"
import { useDisposables } from "../src/hooks/use-disposables"
import Modal from "react-modal"
import { RegisterEmailForm } from "../src/components/RegisterEmailForm"
import Cookies from "js-cookie"

Modal.setAppElement("#portal")

const setHideEmailFormCookieForTop = () => {
  // 明日0時
  const nextDate = new Date()
  nextDate.setDate(nextDate.getDate() + 1)
  nextDate.setHours(0)
  nextDate.setMinutes(0)
  nextDate.setSeconds(0)
  nextDate.setMilliseconds(0)
  // cookie セット
  Cookies.set("hideRegisterEmailForm_top", "1", { expires: nextDate })
}

const CountdownAnimation = ({ seconds, initialSeconds }) => {
  const [styleStatus, setStyleStatus] = useState()

  const isInitialSeconds = seconds === initialSeconds

  // 秒数が変わったら styleStatus を true に
  useEffect(() => {
    setStyleStatus(0)
  }, [seconds])

  // styleStatus が true になったら false に
  useEffect(() => {
    if (!isInitialSeconds && styleStatus === 0) {
      setTimeout(() => {
        setStyleStatus(1)
      }, 100)
      setTimeout(() => {
        setStyleStatus(2)
      }, 200)
    }
  }, [isInitialSeconds, styleStatus])

  const className = classNames(
    isInitialSeconds || (styleStatus === 0 && seconds === initialSeconds - 1)
      ? ""
      : styleStatus === 0
      ? "scale-0"
      : styleStatus === 1 || seconds === 0
      ? "transition-transform duration-100 scale-125"
      : "transition-transform duration duration-[0.8s] scale-0",
    seconds <= 3 && "text-red-500"
  )

  return (
    <div className={className}>
      <div>{isInitialSeconds ? seconds - 1 : seconds}</div>
    </div>
  )
}

export default function QuizApp({ topic, showGameStartModal, numberOfLife, coins }) {
  const [isGameStartModalOpen, setGameStartModalOpen] = useState(showGameStartModal)
  const initialSeconds = topic.category?.time_limit || 10
  return (
    <Countdown
      date={Date.now() + initialSeconds * 1000}
      intervalDelay={0}
      autoStart={!isGameStartModalOpen}
      renderer={({ seconds, milliseconds, completed, api }) => {
        return (
          <>
            <SelectAnswerForm
              topic={topic}
              timedOut={completed}
              onBeforeSubmit={() => {
                api.pause()
              }}
            />
            <div className="fixed left-0 right-0 z-20 top-14">
              <div className="w-full max-w-screen-sm mx-auto bg-white bg-opacity-90">
                <div className="flex h-10 border border-black">
                  <div className="flex items-center w-1/3">
                    <div className="p-1.5">
                      <img src="/images/icons/heart.png" height="26px" width="26px" />
                    </div>
                    <div className="flex justify-center flex-grow text-2xl font-bold">
                      {numberOfLife}
                    </div>
                  </div>
                  <div className="flex items-center w-1/3 border-l border-black">
                    <div className="p-1.5">
                      <img src="/images/icons/coin.png" height="26px" width="26px" />
                    </div>
                    <div className="flex justify-center flex-grow text-2xl font-bold">{coins}</div>
                  </div>
                  <div
                    className="flex items-center w-1/3 border-l border-black"
                    onClick={() => {
                      if (!completed) {
                        if (api.isStopped() || api.isPaused()) {
                          api.start()
                        } else {
                          api.pause()
                        }
                      }
                    }}
                  >
                    <div className="p-1.5">
                      <img src="/images/icons/time.png" height="26px" width="26px" />
                    </div>
                    <div className="flex justify-center flex-grow text-2xl font-bold">
                      <CountdownAnimation
                        seconds={milliseconds > 0 ? seconds + 1 : seconds}
                        initialSeconds={initialSeconds}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Modal
              isOpen={isGameStartModalOpen}
              portalClassName="GameStartModalPortal"
              overlayClassName={" "} // 省略するとデフォルトのスタイルが効いてしまうので空白を設定する
              className={" "}
              closeTimeoutMS={200}
              overlayElement={(props, contentElement) => (
                <div className="GameStartModal">
                  <div {...props}></div>
                  {contentElement}
                </div>
              )}
            >
              <DefaultModalContent
                handleClose={() => {
                  setGameStartModalOpen(false)
                  api.start()
                }}
              />
            </Modal>
          </>
        )
      }}
    />
  )
}

const SelectAnswerForm = ({ topic, timedOut, onBeforeSubmit }) => {
  const formik = useFormik({
    initialValues: { choice_hash_id: null },
    onSubmit: async (values, actions) => {
      onBeforeSubmit()

      const { choice_hash_id } = values
      try {
        const postedData = await fetchApi(
          `/quiz/${topic.hash_id}/vote`,
          "POST",
          JSON.stringify({ choice_hash_id })
        )

        // GAイベント(投票)
        //window.dataLayer = window.dataLayer || []
        //window.dataLayer.push({
        //  event: "vote",
        //  event_params: {
        //    action: "vote",
        //    label: "to_quiz_topic",
        //  },
        //})

        // リダイレクト
        if (choice_hash_id) {
          window.location.href = `/quiz/${topic.hash_id}/choices/${choice_hash_id}`
        } else {
          window.location.href = `/quiz/${topic.hash_id}/choices/timeup`
        }
      } catch (error) {
        console.error(error.message)
        if (error.data && error.data.message) {
          actions.setErrors({
            general: error.data.message || "入力エラーがあります",
          })
        } else {
          actions.setFieldError("general", "エラーが発生しました")
        }
      } finally {
        actions.setSubmitting(false)
      }
    },
  })

  const {
    values,
    errors,
    status,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    setFieldTouched,
    submitForm,
  } = formik

  useEffect(() => {
    if (timedOut) {
      console.log("timed out!")
      // 選択肢ボタンを取得
      const $choiceButtons = Array.prototype.slice.call(
        document.querySelectorAll('[data-choice-button="true"]'),
        0
      )
      // 全ての選択肢を disabled にする
      $choiceButtons.forEach(($choiceButton) => {
        $choiceButton.disabled = true
      })
      // フォーム送信
      submitForm()
    }
  }, [timedOut])

  const setAndSubmit = async (choiceHashId) => {
    // 値をセットしてフォーム送信
    setFieldValue("choice_hash_id", choiceHashId)
    await Promise.resolve()
    submitForm()
  }

  useEffect(() => {
    // 選択肢ボタンを取得
    const $choiceButtons = Array.prototype.slice.call(
      document.querySelectorAll('[data-choice-button="true"]'),
      0
    )
    $choiceButtons.forEach(($choiceButton) => {
      $choiceButton.addEventListener("click", function (e) {
        // クリックされた選択肢のハッシュIDを取得
        const choiceHashId = $choiceButton.dataset.choiceHashId
        // 全ての選択肢を disabled にする
        $choiceButtons.forEach(($choiceButton2) => {
          $choiceButton2.disabled = true
        })
        // 選択したボタンを半透明にする
        $choiceButton.classList.add("opacity-50")
        // フォーム送信
        setAndSubmit(choiceHashId)
      })
    })
  }, [])

  return (
    <form onSubmit={handleSubmit}>
      {errors.general && <div className="mt-4 text-center text-red-500">{errors.general}</div>}
    </form>
  )
}

const DefaultModalContent = ({ handleClose }) => {
  const [isMounted, setIsMounted] = useState(false)
  const d = useDisposables()

  useEffect(() => {
    d.nextFrame(() => setIsMounted(true))
    setTimeout(() => {
      handleClose()
    }, 5 * 1000)
  }, [])

  return (
    <div className="w-full max-w-screen-sm px-4 mx-auto py-36">
      <div className="mb-8">
        <img src="/images/panels/info-start.png" className="w-full" />
      </div>

      <div className="flex items-center mb-6">
        <div className="flex items-center justify-center bg-white rounded-full w-7 h-7">
          <img src="/images/icons/time.png" width="52" height="52" className="w-5 h-5" />
        </div>
        <div className="relative flex-1 -ml-1 border-4 border-white">
          <div className="h-2 bg-gray-500"></div>
          <div
            className={classNames(
              "absolute top-0 right-0 h-2 bg-qc-blue",
              isMounted ? "w-0" : "w-full"
            )}
            style={{ transition: "width 5s linear" }}
          ></div>
        </div>
      </div>

      <div className="flex justify-center">
        <button className="w-1/2" onClick={handleClose}>
          <img src="/images/buttons/ok.png" />
        </button>
      </div>
    </div>
  )
}
