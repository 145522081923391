import React, { useEffect, useState } from "react"
import { FormattedDate, IntlProvider, useIntl } from "react-intl"
import { messages } from "../src/utils/i18n"
import { ChevronLeftIcon, ChevronRightIcon, PlayIcon } from "@heroicons/react/solid"
import { classNames } from "../src/utils/classNames"
import { HiCheckCircle } from "react-icons/hi"
import { fetchApi } from "../src/lib/fetcher"
import useSWR from "swr"

const NumplayCalendarApp = ({
  modeParam,
  beginDateStr,
  locale,
}: {
  modeParam: string
  beginDateStr: string
  locale: string
}) => {
  return (
    <IntlProvider locale={locale} messages={messages[locale].numplay}>
      <Calendar modeParam={modeParam} beginDateStr={beginDateStr} />
    </IntlProvider>
  )
}

export default NumplayCalendarApp

const getDateStr = (date: Date) => {
  return `${date.getFullYear()}-${`0${date.getMonth() + 1}`.slice(-2)}-${`0${date.getDate()}`.slice(
    -2
  )}`
}

const Calendar = ({ modeParam, beginDateStr }: { modeParam: string; beginDateStr: string }) => {
  const intl = useIntl()

  const beginDate = new Date(beginDateStr + "T00:00:00")

  const today = new Date()
  const [currentYear, setCurrentYear] = useState<number>(today.getFullYear())
  const [currentMonth, setCurrentMonth] = useState<number>(today.getMonth())

  const thisMonthLastDate = new Date(currentYear, currentMonth + 1, 0) // 今月の最後の日(来月の0日)

  const currentMonthStartDate = new Date(currentYear, currentMonth, 1) // 選択月の最初の日
  const currentMonthStartDateDayIndex = currentMonthStartDate.getDay() // 選択の最初の日の曜日(0-6)

  const lastDate = new Date(currentYear, currentMonth + 1, 0) // 選択の最後の日(次月の0日)
  const currentMonthDateCount = lastDate.getDate() // 選択の日数(月の末日)

  const lastMonthEndDate = new Date(currentYear, currentMonth, 0) // 前月の最後の日(今月の0日)
  const lastMonthDateCount = lastMonthEndDate.getDate() // // 前月の日数(前月の末日)

  const nextMonthStartDate = new Date(currentYear, currentMonth + 1, 1) // 次月の最初の日

  const onClickPreviousMonth = () => {
    setCurrentYear(lastMonthEndDate.getFullYear())
    setCurrentMonth(lastMonthEndDate.getMonth())
  }

  const onClickNextMonth = () => {
    setCurrentYear(nextMonthStartDate.getFullYear())
    setCurrentMonth(nextMonthStartDate.getMonth())
  }

  const { data, error } = useSWR(
    `/numplay_results?mode=${modeParam}&year=${currentYear}&month=${currentMonth + 1}`,
    fetchApi
  )

  return (
    <div className="text-center select-none">
      <div className="flex items-center text-gray-900">
        <div className="w-8 h-8">
          {beginDate < currentMonthStartDate && (
            <button
              type="button"
              className="rounded-full flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-black hover:bg-gray-100"
              onClick={onClickPreviousMonth}
            >
              <span className="sr-only">Previous month</span>
              <ChevronLeftIcon className="w-5 h-5" />
            </button>
          )}
        </div>
        <div className="flex-auto font-semibold">
          <FormattedDate value={currentMonthStartDate} year="numeric" month="long" />
        </div>
        <div className="w-8 h-8">
          {thisMonthLastDate < today && (
            <button
              type="button"
              className="rounded-full flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-black hover:bg-gray-100"
              onClick={onClickNextMonth}
            >
              <span className="sr-only">Next month</span>
              <ChevronRightIcon className="w-5 h-5" />
            </button>
          )}
        </div>
      </div>
      <div className="grid grid-cols-7 mt-6 text-xs leading-6 text-gray-500">
        {intl.locale === "ja"
          ? ["日", "月", "火", "水", "木", "金", "土"].map((weekday, i) => (
              <div key={i}>{weekday}</div>
            ))
          : ["M", "T", "W", "T", "F", "S", "S"].map((weekday, i) => <div key={i}>{weekday}</div>)}
      </div>
      <div className="grid grid-cols-7 gap-px mt-2 overflow-hidden text-sm bg-gray-200 rounded-lg shadow isolate ring-1 ring-gray-200">
        {[0, 1, 2, 3, 4, 5].map((weekIndex) =>
          [0, 1, 2, 3, 4, 5, 6].map((dayIndex) => {
            const add = intl.locale === "en" ? 1 : 0 // 英語の場合、月曜始まりにするため1日ずらす
            const dateNum = weekIndex * 7 + dayIndex + 1 - currentMonthStartDateDayIndex + add

            const date =
              dateNum < 1 // 前月か
                ? new Date(
                    lastMonthEndDate.getFullYear(),
                    lastMonthEndDate.getMonth(),
                    lastMonthDateCount + dateNum
                  ) // 前月
                : dateNum > currentMonthDateCount // 次月か
                ? new Date(
                    nextMonthStartDate.getFullYear(),
                    nextMonthStartDate.getMonth(),
                    dateNum - currentMonthDateCount
                  ) // 次月
                : new Date(currentYear, currentMonth, dateNum) // それ以外は選択月

            const isInCurrentMonth =
              date.getFullYear() === currentYear && date.getMonth() === currentMonth

            const isToday =
              date.getFullYear() === today.getFullYear() &&
              date.getMonth() === today.getMonth() &&
              date.getDate() === today.getDate()

            return (
              <DateLink
                key={date.toDateString()}
                modeParam={modeParam}
                date={date}
                isInCurrentMonth={isInCurrentMonth}
                isFuture={date > today}
                isBeforeBeginDate={beginDate > date}
                isToday={isToday}
                dailyResultData={data}
              />
            )
          })
        )}
      </div>
    </div>
  )
}

const DateLink = ({
  modeParam,
  date,
  isInCurrentMonth,
  isFuture,
  isBeforeBeginDate,
  isToday,
  dailyResultData,
}: {
  modeParam?: string
  date: Date
  isInCurrentMonth: boolean
  isFuture: boolean
  isBeforeBeginDate: boolean
  isToday: boolean
  dailyResultData?: Record<string, any>
}) => {
  const intl = useIntl()
  const dateTime = getDateStr(date)

  // ローカルストレージにあるか
  const result = dailyResultData?.[dateTime]

  // ノーミス時の最短タイムを取得
  const t = result?.best_finished_seconds
  const hasTime = typeof t === "number"

  return (
    <div className={classNames(isInCurrentMonth ? "bg-white" : "bg-gray-50")}>
      {isInCurrentMonth && (
        <>
          {isFuture || isBeforeBeginDate ? (
            <div className="py-1.5 space-y-1.5 min-h-[60px]">
              <time
                dateTime={dateTime}
                className={classNames("flex items-center justify-center mx-auto", "text-gray-400")}
              >
                {date.getDate()}
              </time>
            </div>
          ) : (
            <a
              href={`/numplay${modeParam}${isToday ? "" : `/daily-challenge/${dateTime}`}`}
              className="group block py-1.5 space-y-1.5 min-h-[66px]"
            >
              <time
                dateTime={dateTime}
                className={classNames("flex items-center justify-center mx-auto", "text-gray-900")}
              >
                {date.getDate()}
              </time>
              {hasTime ? (
                <>
                  <div className="flex justify-center">
                    {intl.locale === "ja" ? (
                      <div className="flex items-center justify-center w-6 h-6 text-sm font-bold text-red-500 transform border-2 border-red-500 rounded-full rotate-12">
                        済
                      </div>
                    ) : (
                      <HiCheckCircle className="text-green-500 transition-colors w-7 h-7 " />
                    )}
                  </div>
                  <div className="text-xs leading-none text-gray-400">
                    {((t / 3600) | 0) > 0 ? `${(t / 3600) | 0}:` : ""}
                    {`${`0${((t % 3600) / 60) | 0}`.slice(-2)}:${`0${t % 60}`.slice(-2)}`}
                  </div>
                </>
              ) : (
                <>
                  <div className="flex justify-center">
                    <PlayIcon className="text-blue-300 transition-colors group-hover:text-blue-500 w-7 h-7" />
                  </div>
                </>
              )}
            </a>
          )}
        </>
      )}
    </div>
  )
}
