import React from "react"

const Button = ({
  children,
  type = "button",
  size,
  rounded,
  fullwidth,
  leftIcon,
  rightIcon,
  color = "light",
  loading = false,
  loaderColor,
  ...props
}) => {
  // 基本
  let buttonClassName = `
    relative inline-flex justify-center items-center
    border
    select-none cursor-pointer
    group
    focus:outline-none
    disabled:cursor-not-allowed`

  // サイズ
  if (size === "sm") {
    buttonClassName += ` text-xs py-1 ${leftIcon ? "pl-1.5" : "pl-2.5"} ${
      rightIcon ? "pr-1.5" : "pr-2.5"
    }`
  } else if (size === "lg") {
    buttonClassName += ` text-base py-2.5 ${leftIcon ? "pl-3" : "pl-6"} ${
      rightIcon ? "pr-3" : "pr-6"
    }`
  } else {
    buttonClassName += ` py-1.5 ${leftIcon ? "pl-2" : "pl-4"} ${rightIcon ? "pr-2" : "pr-4"}`
  }

  if (color === "blue") {
    buttonClassName += `
      bg-blue-500 text-white border-transparent
      hover:bg-blue-600 touch-hover:bg-blue-500
      focus:shadow-outline-blue
      disabled:opacity-50 disabled:bg-blue-500
      `
  } else if (color === "red") {
    buttonClassName += `
      bg-red-500 text-white border-transparent
      hover:bg-red-600 touch-hover:bg-red-500
      focus:shadow-outline-red
      disabled:opacity-50 disabled:bg-red-500
      `
  } else if (color === "light") {
    buttonClassName += `
      bg-gray-100 text-gray-800 border-transparent
      hover:bg-gray-100 touch-hover:bg-gray-100
      focus:shadow-outline-gray
      disabled:opacity-50 disabled:bg-gray-100
    `
  } else if (color === "dark") {
    buttonClassName += `
      bg-gray-800 text-white border-transparent
      hover:bg-black touch-hover:bg-black
      focus:shadow-outline-gray
      disabled:opacity-50 disabled:bg-gray-500
    `
  } else {
    buttonClassName += `
      bg-gray-100 text-gray-800 border-transparent
      hover:bg-gray-100 touch-hover:bg-gray-100
      focus:shadow-outline-gray
      disabled:opacity-50 disabled:bg-gray-100
    `
  }

  // rounded
  buttonClassName += " " + (rounded ? "rounded-full" : "rounded")

  // fullwidth
  buttonClassName += " " + (fullwidth ? "w-full" : "")

  // loading
  buttonClassName += " " + (loading ? "!text-transparent" : "")

  buttonClassName = buttonClassName.replace(/\s+/g, " ")

  return (
    <button type={type} {...props} className={buttonClassName}>
      {leftIcon}
      {children}
      {rightIcon}
      {loading && (
        <svg className="absolute w-4 h-4 animate-spin-fast" viewBox="0 0 32 32">
          <circle
            cx="16"
            cy="16"
            fill="none"
            r="14"
            strokeWidth="4"
            stroke={loaderColor || "white"}
            strokeDasharray="100"
            strokeDashoffset="60"
          ></circle>
        </svg>
      )}
    </button>
  )
}

export default Button
