import React from "react"
import { useField, FieldHookConfig } from "formik"
import { classNames } from "../../utils/classNames"

type TextFieldProps = {
  addClassName?: string
  addClassNameError?: string
} & FieldHookConfig<string>

export const TextField = ({ addClassName, addClassNameError, ...props }: TextFieldProps) => {
  const [field, meta] = useField(props)
  const hasError = meta.touched && meta.error

  return (
    <>
      <input
        {...field}
        placeholder={props.placeholder}
        type={props.type}
        className={classNames(
          "block appearance-none text-base bg-white border border-gray-200 rounded w-full py-1.5 px-2.5 focus:outline-none focus:border-gray-500",
          hasError && "border-red-500",
          addClassName
        )}
      />
      {hasError && (
        <div className={classNames("mt-1 text-xs text-red-500", addClassNameError)}>
          {meta.error}
        </div>
      )}
    </>
  )
}
