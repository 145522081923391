import React from "react"

const LoaderContainer = () => (
  <div className="flex justify-center w-full px-4 py-6 mx-auto">
    <div className="w-6 h-6 animate-spin-fast">
      <svg height="100%" width="100%" viewBox="0 0 32 32">
        <circle cx="16" cy="16" fill="none" r="14" strokeWidth="4" style={{ stroke: "rgb(31, 31, 31)", opacity: "0.2"}}></circle>
        <circle cx="16" cy="16" fill="none" r="14" strokeWidth="4" style={{ stroke: "rgb(31, 31, 31)", strokeDasharray: 80, strokeDashoffset: 60}}></circle>
      </svg>
    </div>
  </div>
)
export default LoaderContainer
