import React from "react"
import { AccountLinkCodeForm } from "../src/components/AccountLinkCodeForm"
import { AccountLinkCodeShareForm } from "../src/components/AccountLinkCodeShareForm"

const AccountLinkCodeFormApp = () => {
  return (
    <>
      <AccountLinkCodeForm title={"他のブラウザで遊ぶ人のために"} />
      <AccountLinkCodeShareForm />
    </>
  )
}
export default AccountLinkCodeFormApp
