export async function fetchJson(...args) {
  try {
    const response = await fetch(...args)

    // if the server replies, there's always some data in json
    // if there's a network error, it will throw at the previous line
    const data = await response.json()

    if (response.ok) {
      return data
    }

    const error = new Error(response.statusText)
    error.response = response
    error.data = data
    throw error
  } catch (error) {
    if (!error.data) {
      error.data = { message: "エラーが発生しました" }
    }
    throw error
  }
}

export async function fetchApi(url, method = "GET", body = null, headers = null) {
  const csrfToken = document.head.querySelector("meta[name='csrf-token']").content
  return await fetchJson(url, {
    method,
    credentials: "include",
    mode: "cors",
    headers: headers || {
      "Content-Type": "application/json",
      "X-CSRF-TOKEN": csrfToken,
    },
    body,
  })
}
