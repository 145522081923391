// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import "../css/tailwind"
import "scroll-behavior-polyfill"

import "lazysizes"
import "lazysizes/plugins/parent-fit/ls.parent-fit"

//import Rails from "@rails/ujs"
//import * as ActiveStorage from "@rails/activestorage"
//import "channels"

//Rails.start()
//ActiveStorage.start()

// react_ujs
var componentRequireContext = require.context("apps", true)
var ReactRailsUJS = require("react_ujs")
ReactRailsUJS.useContext(componentRequireContext)

document.addEventListener("DOMContentLoaded", function () {
  // 非表示トグル
  const $toggleHiddenSwitches = Array.prototype.slice.call(
    document.querySelectorAll("[data-toggle-hidden-switch=true]"),
    0
  )
  if ($toggleHiddenSwitches.length > 0) {
    $toggleHiddenSwitches.forEach(($el) => {
      const $target = document.getElementById($el.dataset.target)
      if ($target) {
        $el.addEventListener("click", function (e) {
          $target.classList.toggle("hidden")
        })
      }
    })
  }

  // 表示スイッチ
  const $showSwitches = Array.prototype.slice.call(
    document.querySelectorAll("[data-show-switch=true]"),
    0
  )
  if ($showSwitches.length > 0) {
    $showSwitches.forEach(($el) => {
      const $target = document.getElementById($el.dataset.target)
      if ($target) {
        $el.addEventListener("click", function (e) {
          $target.classList.remove("hidden")
        })
      }
    })
  }

  // ヘッダードロップダウンメニュー非表示
  const $headerDropdown = document.getElementById("js-header-dropdown")
  const $headerDropdown2 = document.getElementById("js-header-dropdown-2")
  if ($headerDropdown) {
    document.body.addEventListener("click", function (e) {
      // #js-header-dropdown 内以外、開くボタン以外、の部分をクリックした場合
      if (
        e.target.closest("#js-header-dropdown") === null &&
        e.target.closest("#js-header-dropdown-switch") === null &&
        !$headerDropdown.classList.contains("hidden")
      ) {
        $headerDropdown.classList.add("hidden")
      }
      if (
        e.target.closest("#js-header-dropdown-2") === null &&
        e.target.closest("#js-header-dropdown-switch-2") === null &&
        !$headerDropdown2.classList.contains("hidden")
      ) {
        $headerDropdown2.classList.add("hidden")
      }
    })
  }

  const navCategory = document.getElementById("nav-category")
  if (navCategory) {
    const currentCategoryName = navCategory.dataset.currentCategoryName
    if (currentCategoryName) {
      let scrollLeft = 0
      const targetElement = document.getElementById(`nav-category-${currentCategoryName}`)
      if (targetElement) {
        const targetClientRect = targetElement.getBoundingClientRect()
        const targetPX = window.pageXOffset + targetClientRect.left // targetのページ内Xポジション
        const targetInitialPX = targetPX + navCategory.scrollLeft // navCategoryが横スクロールされてない状態の、targetのページ内Xポジション
        const targetWidth = targetElement.clientWidth // targetの幅

        const navClientRect = navCategory.getBoundingClientRect()
        const navPX = window.pageXOffset + navClientRect.left // navCategoryのページ内Xポジション
        const navWidth = navCategory.clientWidth // navCategoryの幅
        const navContentWidth = navCategory.scrollWidth // navCategory内のコンテンツの幅
        const navMaxScroll = navContentWidth - navWidth // navのmaxのスクロール値

        // navCategoryが横スクロールされてない状態の、targetのページ内Xポジション - navのページ内Xポジション から
        scrollLeft = targetInitialPX - navPX - navWidth / 2 + targetWidth / 2

        // 0以下の場合は0
        if (scrollLeft < 0) {
          scrollLeft = 0
        }
        if (scrollLeft > navMaxScroll) {
          scrollLeft = navMaxScroll
        }
      }
      // navCategoryをスクロール
      navCategory.scrollLeft = scrollLeft
    }
  }
})
