import React, { useState, useEffect, useCallback } from "react"

import { fetchApi } from "../src/lib/fetcher"
import { classNames } from "../src/utils/classNames"

import Modal from "react-modal"
Modal.setAppElement("#portal")

export default function AnswerNextButtons({
  shouldAutoRedirect,
  isFinished,
  finishedGameHashId,
  currentQuizPath,
  nextQuizPath,
  resultPath,
  twitterShareUrl,
}) {
  const [timerStarted, setTimerStarted] = useState(false)
  const [autoRedirectCanceled, setAutoRedirectCanceled] = useState(false)
  const [gameOverModalIsOpen, setGameOverModalIsOpen] = useState(false)

  const handleTimeout = () => {
    if (isFinished) {
      // モーダルを表示
      setGameOverModalIsOpen(true)
    } else {
      window.location.href = nextQuizPath
    }
  }

  useEffect(() => {
    if (shouldAutoRedirect && !autoRedirectCanceled) {
      setTimerStarted(true)
      const timer = setTimeout(() => {
        //some action
        console.log("6 seconds has passed. TimerID " + String(timer) + " has finished.")
        handleTimeout()
      }, 6 * 1000)
      console.log("TimerID " + String(timer) + " has started.")

      //クリーンアップ
      return () => {
        console.log("Restart button was clicked. TimerID " + String(timer) + " was canceled.")
        clearTimeout(timer)
      }
    }
  }, [shouldAutoRedirect, autoRedirectCanceled])

  return (
    <>
      {shouldAutoRedirect && (
        <div className="flex items-center mb-6">
          <div className="flex items-center justify-center bg-white rounded-full w-7 h-7">
            <img src="/images/icons/time.png" width="52" height="52" className="w-5 h-5" />
          </div>
          <div className="relative flex-1 -ml-1 border-4 border-white">
            <div className="h-2 bg-gray-500" />
            <div
              className={classNames(
                "absolute top-0 right-0 h-2 bg-qc-blue",
                timerStarted ? "w-0" : "w-full"
              )}
              style={{ transition: "width 6s linear" }}
            />
          </div>
        </div>
      )}

      <div>
        <div className="flex mb-6">
          <div className="flex items-center w-1/2">
            <a
              href={currentQuizPath}
              className="flex items-start h-auto w-4/5 max-w-[258px]"
              onClick={() => {
                // GA
                //window.dataLayer = window.dataLayer || []
                //window.dataLayer.push({
                //  event: 'link-click',
                //  event_params: {
                //    action: 'click_topic_top_link--under_answer_text',
                //    label: 'to_quiz_topic',
                //  },
                //})
              }}
            >
              <img
                src="/images/buttons/back-quiz.png"
                width="258"
                height="100"
                className="w-full h-auto"
              />
            </a>
          </div>
          <div className="flex items-center justify-end w-1/2">
            {isFinished ? (
              <a
                className="flex items-start h-auto w-4/5 max-w-[258px] cursor-pointer"
                onClick={() => {
                  setGameOverModalIsOpen(true)
                  setAutoRedirectCanceled(true)

                  // GA
                  //window.dataLayer = window.dataLayer || []
                  //window.dataLayer.push({
                  //  event: 'open-gameover-modal',
                  //  event_params: {
                  //    action: 'click_gameover_modal_open_button',
                  //    label: 'to_quiz_topic',
                  //  },
                  //})
                }}
              >
                <img
                  src="/images/buttons/next-quiz.png?v=2"
                  width="258"
                  height="100"
                  className="w-full h-auto"
                />
              </a>
            ) : (
              <a
                href={nextQuizPath}
                className="flex items-start h-auto w-4/5 max-w-[258px]"
                onClick={() => {
                  // GA
                  //window.dataLayer = window.dataLayer || []
                  //window.dataLayer.push({
                  //  event: 'link-click',
                  //  event_params: {
                  //    action: 'click_next_topic_link',
                  //    label: 'to_quiz_topic',
                  //  },
                  //})
                }}
              >
                <img
                  src="/images/buttons/next-quiz.png?v=2"
                  width="258"
                  height="100"
                  className="w-full h-auto"
                />
              </a>
            )}
          </div>
        </div>

        <div className="flex justify-center">
          <button
            className="flex items-start w-1/2 h-auto"
            onClick={() => {
              setAutoRedirectCanceled(true)

              // GA
              //window.dataLayer = window.dataLayer || []
              //window.dataLayer.push({
              //  event: 'share-twitter',
              //  event_params: {
              //    action: 'click_share_answer',
              //    label: 'twitter',
              //  },
              //})

              window.open(
                twitterShareUrl,
                "TWwindow",
                "width=560, height=470, menubar=no, toolbar=no, scrollbars=yes"
              )
            }}
          >
            <img
              src="/images/buttons/tweet-answer.png"
              width="752"
              height="156"
              className="w-full h-auto"
            />
          </button>
        </div>

        {gameOverModalIsOpen && (
          <GameOverModal
            finishedGameHashId={finishedGameHashId}
            nextQuizPath={nextQuizPath}
            resultPath={resultPath}
            twitterShareUrl={twitterShareUrl}
          />
        )}
      </div>
    </>
  )
}

const GameOverModal = ({ finishedGameHashId, nextQuizPath, resultPath, twitterShareUrl }) => {
  const [timerStarted, setTimerStarted] = useState(false)
  const [autoRedirectCanceled, setAutoRedirectCanceled] = useState(false)

  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)

  const [lifeIsRecovered, setLifeIsRecovered] = useState(false)

  useEffect(() => {
    if (!autoRedirectCanceled) {
      setTimeout(() => {
        setTimerStarted(true)
      }, 10) // アニメーションが動かないことがあるので遅らせる

      const timer = setTimeout(() => {
        //some action
        console.log("10 seconds has passed. TimerID " + String(timer) + " has finished.")
        window.location.href = resultPath
      }, 10 * 1000)
      console.log("TimerID " + String(timer) + " has started.")

      //クリーンアップ
      return () => {
        console.log("Restart button was clicked. TimerID " + String(timer) + " was canceled.")
        clearTimeout(timer)
      }
    }
  }, [autoRedirectCanceled])

  const recoverLife = async () => {
    try {
      await fetchApi(`/my_games/${finishedGameHashId}/recover_life`, "POST", null)
    } catch (e) {
      setErrorMessage("エラーが発生しました。再読み込みしてください。")
      return
    }
  }

  return (
    <>
      <div className="fixed inset-0 z-50">
        <div className="absolute inset-0 bg-black opacity-90" />
        <div className="absolute inset-0 overflow-y-scroll">
          <div className="w-full max-w-screen-sm px-4 mx-auto mt-10">
            {lifeIsRecovered ? (
              <>
                <div className="mb-8">
                  <img
                    src="/images/panels/life-recovered.png"
                    className="w-full"
                    alt="ライフが回復しました"
                  />
                </div>

                <div className="flex justify-center">
                  <a
                    href={nextQuizPath}
                    className="w-1/2"
                    onClick={() => {
                      // GA
                      //window.dataLayer = window.dataLayer || []
                      //window.dataLayer.push({
                      //  event: 'link-click',
                      //  event_params: {
                      //    action: 'click_next_topic_link__tsuzuki',
                      //    label: 'to_quiz_topic',
                      //  },
                      //})
                    }}
                  >
                    <img src="/images/buttons/continue.png" width={840} height={240} alt="続き" />
                  </a>
                </div>
              </>
            ) : (
              <>
                <div className="mb-8">
                  <img
                    src="/images/panels/game-over.png"
                    className="w-full"
                    alt="ライフがなくなりました"
                  />
                </div>

                <div className="flex items-center mb-6">
                  <div className="flex items-center justify-center bg-white rounded-full w-7 h-7">
                    <img src="/images/icons/time.png" width="52" height="52" className="w-5 h-5" />
                  </div>
                  <div className="relative flex-1 -ml-1 border-4 border-white">
                    <div className="h-2 bg-gray-500"></div>
                    <div
                      className={classNames(
                        "absolute top-0 right-0 h-2 bg-qc-blue",
                        timerStarted ? "w-0" : "w-full"
                      )}
                      style={{ transition: "width 10s linear" }}
                    ></div>
                  </div>
                </div>

                <div className="flex justify-center mb-6">
                  <a
                    href={resultPath}
                    className="w-1/2"
                    onClick={() => {
                      // GA
                      //window.dataLayer = window.dataLayer || []
                      //window.dataLayer.push({
                      //  event: 'link-click',
                      //  event_params: {
                      //    action: 'click_result_link',
                      //    label: 'to_quiz_topic',
                      //  },
                      //})
                    }}
                  >
                    <img src="/images/buttons/show-result.png" alt="結果を見る" />
                  </a>
                </div>

                <div className="flex items-start justify-center w-2/3 mx-auto">
                  <img src="/images/panels/confirm-continue.png" />
                </div>

                <div className="flex justify-center">
                  <button
                    className="flex items-start w-2/3 h-auto"
                    onClick={() => {
                      setIsLoading(true)
                      setAutoRedirectCanceled(true)

                      recoverLife()

                      // GA
                      //window.dataLayer = window.dataLayer || []
                      //window.dataLayer.push({
                      //  event: 'recover-life-and-share-twitter',
                      //  event_params: {
                      //    action: 'click_share_answer__life0',
                      //    label: 'twitter',
                      //  },
                      //})

                      setLifeIsRecovered(true)

                      window.open(
                        twitterShareUrl,
                        "TWwindow",
                        "width=560, height=470, menubar=no, toolbar=no, scrollbars=yes"
                      )
                    }}
                    disabled={isLoading}
                  >
                    <img
                      src="/images/buttons/tweet-and-recover.png"
                      width="978"
                      height="310"
                      className="w-full h-auto"
                    />
                  </button>
                </div>
                {errorMessage && (
                  <div className="mt-2 text-xs text-center text-red-500">{errorMessage}</div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
