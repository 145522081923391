import { Formik } from "formik"
import React, { useState } from "react"
import Button from "./Button"
import { Modal } from "./Modal"
import { fetchApi } from "../lib/fetcher"
import toast, { Toaster } from "react-hot-toast"
import copy from "copy-to-clipboard"

export const AccountLinkCodeForm = ({ title }: { title: string }) => {
  const [linkCode, setLinkCode] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false)

  return (
    <>
      <div className="mb-2 text-base font-bold">{title}</div>
      <div className="mb-4 text-sm">
        他のブラウザで開いた時に、この連携IDを登録すると今までのプレイデータの引き継ぎ・連携ができるようになります。
      </div>
      <div>
        <Formik
          initialValues={{ general: null }}
          onSubmit={async (_, actions) => {
            try {
              // 連携IDを発行
              const { link_code } = await fetchApi("/account_link_codes", "POST", null)
              // コードをセット
              setLinkCode(link_code)
              // モーダルオープン
              setIsModalOpen(true)
            } catch (error) {
              if (error.data && error.data.message) {
                actions.setErrors({
                  general: error.data.message,
                })
              } else {
                actions.setFieldError("general", "エラーが発生しました")
              }
            } finally {
              actions.setSubmitting(false)
            }
          }}
        >
          {({ errors, handleSubmit, isSubmitting }) => (
            <form>
              <div className="text-center">
                <Button
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                  loading={isSubmitting}
                  rounded={false}
                  color="blue"
                  size={undefined}
                  fullwidth={undefined}
                  leftIcon={undefined}
                  rightIcon={undefined}
                  loaderColor={undefined}
                >
                  連携IDを発行する
                </Button>
              </div>
              {errors.general && (
                <div className="mt-4 text-center text-red-500">{errors.general}</div>
              )}
            </form>
          )}
        </Formik>
      </div>
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <div className="p-4 text-center">
          <div className="mb-2">＜連携ID＞</div>
          <div className="mb-4">
            <input
              className="text-2xl font-bold text-center bg-gray-50 form-input"
              value={linkCode}
              readOnly
            />
          </div>
          <Button
            disabled={false}
            loading={false}
            rounded={false}
            color="blue"
            size={undefined}
            fullwidth={undefined}
            leftIcon={undefined}
            rightIcon={undefined}
            loaderColor={undefined}
            onClick={() => {
              // コピー
              copy(linkCode, {
                format: "text/plain",
              })

              // トースト表示
              toast("コピーしました")
            }}
          >
            連携IDをコピー
          </Button>
        </div>
      </Modal>
      <Toaster
        position="top-center"
        toastOptions={{
          className: "font-sans",
          style: {
            border: "none",
            padding: "4px",
            background: "rgba(0,0,0,75%)",
            color: "white",
          },
        }}
        containerStyle={{
          top: 100,
        }}
      />
    </>
  )
}
