import { object, string, mixed } from 'yup'

export default object().shape({
  avatarImageDataUrl: string()
    .typeError('不正な値です')
    .nullable()
    .matches(/^data\:image\/jpeg\;base64\,.+/, { message: '不正な値です' }),
  display_name: string()
    .typeError('不正な値です')
    .required('入力してください')
    .max(100, '100文字以内で入力してください'),
})
