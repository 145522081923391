import React from "react"
import { useIntl } from "react-intl"
import { classNames } from "../../src/utils/classNames"
import { useNumberPlaceBoardContext } from "../context/NumberPlaceBoardContext"
import { Modal } from "../../src/components/Modal"
import { ShareArea } from "./ShareArea"

export const ShareModal = () => {
  const {
    isMini,
    mode,
    isShareModalOpen,
    closeShareModal,
    dailyChallengeNumber,
    resultArray,
    missCount,
    hintCount,
    displayTime,
  } = useNumberPlaceBoardContext()

  const intl = useIntl()
  const title = intl.formatMessage({ id: `title_${mode}` })

  return (
    <>
      <Modal
        isOpen={isShareModalOpen}
        onClose={() => {
          closeShareModal()
        }}
      >
        <div className="p-4">
          <div className="mb-4 font-bold text-center texl-lg">
            {title} {dailyChallengeNumber}
          </div>
          <div className="mb-4 text-center">
            <div>
              <div className="inline-flex justify-between space-x-2">
                <div>Miss {missCount}</div>
                <div>Hint {hintCount}</div>
                <div>Time {displayTime}</div>
              </div>
            </div>
            <div className="inline-block">
              <div className={classNames("grid gap-0.5", isMini ? "grid-cols-6" : "grid-cols-9")}>
                {resultArray.map((cell, index) => {
                  return (
                    <img
                      key={index}
                      src={`https://cdn.jsdelivr.net/gh/twitter/twemoji@latest/assets/svg/${
                        typeof cell === "string" ? `3${cell}-20e3` : cell ? "1f7e9" : "1f7e5"
                      }.svg`}
                      className="w-5 h-5"
                    />
                  )
                })}
              </div>
            </div>
          </div>
          <ShareArea position="modal" eventLabel="popup" />
        </div>
      </Modal>
    </>
  )
}
