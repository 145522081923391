export const messages = {
  en: {
    numplay: {
      title: "NumPlay",
      titleMini: "NumPlay 6x6",
      title_daily_9x9: "NumPlay",
      title_daily_9x9_easy: "NumPlay Easy",
      title_daily_6x6: "NumPlay 6x6",
      Undo: "Undo",
      Delete: "Delete",
      Notes: "Notes",
      Hint: "Hint",
      "Check answer": "Check answer",
      "Check answer?": "Check answer?",
      Restart: "Restart",
      "Restart?": "Restart?",
      Cancel: "Cancel",
      OK: "OK",
      Tweet: "Tweet",
      Share: "Share",
      "Copied results to clipboard": "Copied results to clipboard",
      "Next {title}": "Next {title}",
      "daily challenge date": "",
      shareUrl: "https://quizcastle.com/numplay",
      "Play Next:": "Play Next:",
      "Other NumPlay:": "Other NumPlay:",
      Past: "Past",
      "Play past {title}": "Play past {title}",
      "How To Play": "How To Play",
      "How to Play {title}": "How to Play {title}",
      "{title} is one of the number puzzle games called Sudoku.":
        "{title} is one of the number puzzle games called Sudoku.",
      "Guess the numbers in the blank squares and fill in the numbers.":
        "Guess the numbers in the blank squares and fill in the numbers.",
      Rules: "Rules",
      "Every horizontal and vertical row must contain each number from 1 to {maxNum}.":
        "Every horizontal and vertical row must contain each number from 1 to {maxNum}.",
      "Within the {blockNxN} frame, you must also find each number from 1 to {maxNum}.":
        "Within the {blockNxN} frame, you must also find each number from 1 to {maxNum}.",
      "Use the numbers already displayed as hints.": "Use the numbers already displayed as hints.",
      "Complete all the blank squares using the Notes and Hint options if needed.":
        "Complete all the blank squares using the Notes and Hint options if needed.",
      "A new {title} will be released daily at midnight.":
        "A new {title} will be released daily at midnight.",
      Close: "Close",
      "Use Hint to get the answer to one square.": "Use Hint to get the answer to one square.",
    },
  },
  ja: {
    numplay: {
      title: "NumPlay",
      titleMini: "NumPlay 6x6",
      title_daily_9x9: "NumPlay",
      title_daily_9x9_easy: "NumPlay Easy",
      title_daily_6x6: "NumPlay 6x6",
      Undo: "元に戻す",
      Delete: "消す",
      Notes: "メモ",
      Hint: "ヒント",
      "Check answer": "答え合わせ",
      "Check answer?": "答え合わせしますか？",
      Restart: "最初から",
      "Restart?": "リセットしますか？",
      Cancel: "キャンセル",
      OK: "OK",
      Tweet: "ツイート",
      Share: "シェア",
      "Copied results to clipboard": "コピーしました",
      "Next {title}": "次の{title}まで",
      "daily challenge date": "の出題",
      shareUrl: "https://jp.quizcastle.com/numplay",
      "Play Next:": "次はこちら:",
      "Other NumPlay:": "他のNumPlayシリーズ:",
      Past: "過去の",
      "Play past {title}": "過去の{title}を日別に遊ぶ",
      "How To Play": "遊び方",
      "How to Play {title}": "{title}の遊び方",
      "{title} is one of the number puzzle games called Sudoku.": " ",
      "Guess the numbers in the blank squares and fill in the numbers.":
        "空欄の数字を予想し、埋めていきましょう。",
      Rules: "ルール",
      "Every horizontal and vertical row must contain each number from 1 to {maxNum}.":
        "横一列・縦一列にはそれぞれ1～{maxNum}の数字が1つずつ入ります。",
      "Within the {blockNxN} frame, you must also find each number from 1 to {maxNum}.":
        "{blockNxN}の枠内にも1～{maxNum}の数字が1つずつ入ります。",
      "Use the numbers already displayed as hints.": "既に表示されている数字がヒントです。",
      "Complete all the blank squares using the Notes and Hint options if needed.":
        "行き詰まったら「メモ」や「ヒント」も使いこなしながら、全てのマスを埋めていきましょう！",
      "A new {title} will be released daily at midnight.":
        "新しい{title}は毎日24時に出題されます。",
      Close: "この説明を閉じる",
      "Use Hint to get the answer to one square.": "ヒントを使うと1マス埋められます。",
    },
  },
}
