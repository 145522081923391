import React, { useState } from "react"
import { useFormik, Formik } from "formik"
import { object, string } from "yup"
import { fetchApi } from "../lib/fetcher"
import { TextField } from "./form/TextField"
import { CheckIcon } from "@heroicons/react/solid"

declare global {
  interface Window {
    dataLayer: Record<string, unknown>[]
  }
}

const validationSchema = object().shape({
  email: string()
    .typeError("不正な値です")
    .required("入力してください")
    .email("メールアドレスの形式で入力してください")
    .max(256, "256文字以内で入力してください"),
})

type RegisterEmailFormProps = {
  buttonImageSrc?: string
  handleSubmitSucceeded?: () => void
}

export const RegisterEmailForm = ({
  buttonImageSrc = "/images/buttons/tourokusuru.png",
  handleSubmitSucceeded,
}: RegisterEmailFormProps) => {
  const [termChecked, setTermChecked] = useState(true)
  return (
    <>
      <Formik
        initialValues={{ email: "", general: null }}
        validationSchema={validationSchema}
        onSubmit={async ({ email }, actions) => {
          try {
            await fetchApi(`/user_data/register_email`, "POST", JSON.stringify({ email }))
            actions.setSubmitting(false)
            handleSubmitSucceeded?.()
          } catch (error) {
            console.error(error.message)
            if (error.data && error.data.message && error.data.errors) {
              actions.setErrors(error.data.errors)
            } else {
              actions.setFieldError("general", "エラーが発生しました")
            }
            actions.setSubmitting(false)
          }
        }}
      >
        {({
          values,
          errors,
          status,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          setFieldTouched,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <TextField
                name="email"
                type="text"
                placeholder="kuizu.taro@quizcastle.com"
                addClassName="text-center font-bold placeholder-gray-300"
                addClassNameError="text-center"
              />
            </div>
            <div className="flex justify-center mb-4">
              <button
                type="submit"
                disabled={!termChecked}
                className="relative inline-flex items-center justify-center w-2/3 cursor-pointer select-none sm:w-1/2 focus:outline-none disabled:cursor-not-allowed disabled:opacity-50"
              >
                <img src={buttonImageSrc} className="w-full" />
              </button>
            </div>
            {errors.general && (
              <div className="my-4 text-xs text-center text-red-500">{errors.general}</div>
            )}
          </form>
        )}
      </Formik>
      <div className="flex items-center justify-center text-xs">
        <div
          className={`flex h-4 w-4 bg-white cursor-pointer select-none items-center justify-center rounded-sm border border-gray-300`}
          onClick={() => {
            setTermChecked(!termChecked)
          }}
        >
          {termChecked && <CheckIcon className="h-3.5 w-3.5 text-black" />}
        </div>
        <div className="ml-2 font-bold">
          <a href="/terms" target="_blank" rel="nofollow" className="text-blue-500">
            利用規約
          </a>
          に同意した上で登録する
        </div>
      </div>
    </>
  )
}
