import React, { useRef, useState } from "react"
import { useNumberPlaceBoardContext } from "../context/NumberPlaceBoardContext"
import { FaTwitter } from "react-icons/fa"
import { FiChevronRight, FiShare2, FiX } from "react-icons/fi"
import Countdown from "react-countdown"
import { classNames } from "../../src/utils/classNames"
import { useIntl } from "react-intl"
import toast from "react-hot-toast"
import copy from "copy-to-clipboard"
import { AccountLinkCodeForm } from "../../src/components/AccountLinkCodeForm"
import useSWR from "swr"
import { fetchApi } from "../../src/lib/fetcher"
import { AccountLinkCodeShareForm } from "../../src/components/AccountLinkCodeShareForm"
import { useOnClickOutside } from "../../src/hooks/useClickOutside"

const getDateStr = (date: Date) => {
  return `${date.getFullYear()}-${`0${date.getMonth() + 1}`.slice(-2)}-${`0${date.getDate()}`.slice(
    -2
  )}`
}

export const ShareArea = ({
  position,
  eventLabel,
}: {
  position: "default" | "modal"
  eventLabel: string
}) => {
  const {
    mode,
    modeParam,
    isDailyChallengeMode,
    hashId,
    solved,
    shareText,
    nextDate,
    missCount,
    hintCount,
    displayTime,
    dailyChallengeNumber,
  } = useNumberPlaceBoardContext()

  const { data, error } = useSWR("/current_user", fetchApi)

  const intl = useIntl()

  const title = intl.formatMessage({ id: `title_${mode}` })
  const twitterShareText = `${title} ${dailyChallengeNumber}\nMiss ${missCount} Hint ${hintCount} Time ${displayTime}\n${shareText}\n#ナンプレ #クイズキャッスル\n${intl.formatMessage(
    { id: "shareUrl" }
  )}${modeParam}${isDailyChallengeMode ? "" : `/${hashId}`}?date=${getDateStr(new Date())}`

  return solved && shareText ? (
    <>
      <div className="flex justify-between mb-4 -mx-4 divide-x">
        {isDailyChallengeMode && nextDate && (
          <div className="flex flex-col justify-center w-2/5 px-4 text-center">
            <div className="text-xs">{intl.formatMessage({ id: "Next {title}" }, { title })}</div>
            <div className="font-mono text-xl">
              <Countdown date={nextDate} daysInHours />
            </div>
          </div>
        )}
        <div
          className={classNames(
            "px-4 space-y-2",
            isDailyChallengeMode && nextDate ? "w-3/5" : "w-full"
          )}
        >
          <div className="relative">
            <button
              className="relative inline-flex items-center justify-center w-full py-2 font-semibold text-white transition-colors bg-blue-500 rounded-md hover:bg-blue-600"
              onClick={() => {
                // GAイベント
                //window.dataLayer = window.dataLayer || []
                //window.dataLayer.push({
                //  event: "numplay_share_tweet",
                //  event_params: {
                //    action: "numplay_share_tweet",
                //    label: eventLabel,
                //  },
                //})

                window.open(
                  `https://twitter.com/intent/tweet?text=${encodeURIComponent(twitterShareText)}`,
                  "TWwindow",
                  "width=560, height=470, menubar=no, toolbar=no, scrollbars=yes"
                )
              }}
            >
              <FaTwitter className="w-4 h-4 mr-2" />
              <span>{intl.formatMessage({ id: "Tweet" })}</span>
            </button>
          </div>
          <div>
            <button
              className="relative inline-flex items-center justify-center w-full py-2 font-semibold text-white transition-colors bg-gray-400 rounded-md hover:bg-gray-500"
              onClick={() => {
                // コピー
                copy(twitterShareText, {
                  format: "text/plain",
                })

                // GAイベント
                //window.dataLayer = window.dataLayer || []
                //window.dataLayer.push({
                //  event: "numplay_share_copy",
                //  event_params: {
                //    action: "numplay_share_copy",
                //    label: eventLabel,
                //  },
                //})

                // トースト表示
                toast(intl.formatMessage({ id: "Copied results to clipboard" }))
              }}
            >
              <FiShare2 className="w-4 h-4 mr-2" />
              <span>{intl.formatMessage({ id: "Share" })}</span>
            </button>
          </div>
        </div>
      </div>
      <div className="mb-4">
        <div className="px-4 mb-2 text-xxs">{intl.formatMessage({ id: "Play Next:" })}</div>
        <a
          href={`/numplay${modeParam}/daily-challenge`}
          className={classNames(
            "flex items-center justify-between px-4 py-3",
            position === "modal" ? "border-t border-b" : "border border-gray-300 rounded shadow-sm"
          )}
        >
          <div>
            <div className="text-base font-bold">
              {intl.formatMessage({ id: "Play past {title}" }, { title })}
            </div>
          </div>
          <div>
            <FiChevronRight className="w-5 h-5" />
          </div>
        </a>
      </div>

      {position === "default" && intl.locale === "ja" && data && !data.currentUser && (
        <div className="p-4 mb-4 border">
          <AccountLinkCodeForm title={"他のブラウザで遊ぶ人のために"} />
          <AccountLinkCodeShareForm />
        </div>
      )}
      <div>
        <div className="px-4 mb-2 text-xxs">{intl.formatMessage({ id: "Other NumPlay:" })}</div>
        <div className="grid grid-cols-2">
          {isDailyChallengeMode && (
            <>
              {[
                { href: "/numplay", title: intl.formatMessage({ id: "title_daily_9x9" }) },
                { href: "/numplay6x6", title: intl.formatMessage({ id: "title_daily_6x6" }) },
                { href: "/numplayeasy", title: intl.formatMessage({ id: "title_daily_9x9_easy" }) },
              ]
                .filter(({ href }) => href !== `/numplay${modeParam}`)
                .map(({ href, title }, index) => (
                  <a
                    key={title}
                    href={href}
                    className={classNames(
                      "flex items-center justify-between px-4 py-3 border-t border-b first:border-r",
                      position === "default" &&
                        "mb-4 border-gray-300 shadow-sm" +
                          (index % 2 === 0 ? " border-l" : " border-r")
                    )}
                  >
                    <div>
                      <div className="text-base font-bold">{title}</div>
                    </div>
                    <div>
                      <FiChevronRight className="w-5 h-5" />
                    </div>
                  </a>
                ))}
            </>
          )}
        </div>
      </div>
    </>
  ) : (
    <></>
  )
}
