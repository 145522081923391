import React from "react"
import { useFirebaseUser } from "../src/lib/firebase"
import LoaderContainer from "../src/components/LoaderContainer"
import { LoginButtonPanel } from "../src/components/LoginButtonPanel"
import { useLogin } from "./Login"

export default function Signup() {
  const [user, loadingUser, userError] = useFirebaseUser()

  const { loginLoading } = useLogin()

  // ローディング中
  if (loadingUser || loginLoading) {
    return <LoaderContainer />
  }

  return (
    <div className="container max-w-sm px-4 mx-auto">
      <div className="pt-8 pb-4 text-center">
        <h1 className="text-2xl font-semibold leading-relaxed">会員登録</h1>
      </div>
      <div className="mb-4 text-xs text-center">許可なくツイート等は行われません。</div>

      <LoginButtonPanel isSignup />
    </div>
  )
}
