import React, {
  createContext,
  ReactElement,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react"
import clone from "just-clone"
import Cookies from "js-cookie"
import { useStopwatch } from "react-timer-hook"
import { NumberPlaceBoard } from "../../apps/NumberPlaceBoardApp"
import { fetchApi } from "../../src/lib/fetcher"
import { useConfetti } from "../components/Confetti"

type NumberPlaceBoardContextProps = {
  isDailyChallengeMode: boolean
  isMini: boolean
  mode: string
  modeParam: string
  dailyChallengeDateForDisp: string
  hashId: string
  numberSelected: string
  setNumberSelected: React.Dispatch<React.SetStateAction<string>>
  currentBoard: Board
  selectedCellIndex: number
  setSelectedCellIndex: React.Dispatch<React.SetStateAction<number>>
  initBoardArray: string[]
  solvedBoardArray: string[]
  resetGame: () => void
  fillNumber: (number: string) => void
  toggleMemo: (number: string) => void
  undo: () => void
  hint: () => void
  hintCount: number
  checkAnswer: () => void
  displayTime: string
  paused: boolean
  stopwatchStart: () => void
  stopwatchPause: () => void
  solved: boolean
  historyArray: History[]
  memoMode: boolean
  toggleMemoMode: () => void
  isShareModalOpen: boolean
  openShareModal: () => void
  closeShareModal: () => void
  isAccountLinkModalOpen: boolean
  closeAccountLinkModal: () => void
  isHowToPlayModalOpen: boolean
  openHowToPlayModal: () => void
  closeHowToPlayModal: () => void
  isAllFilldOnce: boolean
  allHint: () => void
  resultArray: (string | boolean)[]
  shareText: string
  nextDate: Date
  missCount: number
  dailyChallengeNumber: string
  alreadyFinished: boolean
  handleOperation: () => void
  secondsFromLastOpration: number
  boardAddClassName: string
  Confetti: ReactElement
}

const NumberPlaceBoardContext = createContext<NumberPlaceBoardContextProps>({
  isDailyChallengeMode: false,
  isMini: false,
  mode: "",
  modeParam: "",
  dailyChallengeDateForDisp: null,
  hashId: "",
  numberSelected: "0",
  setNumberSelected: () => {},
  currentBoard: [],
  selectedCellIndex: 0,
  setSelectedCellIndex: () => {},
  initBoardArray: [],
  solvedBoardArray: [],
  resetGame: () => {},
  fillNumber: () => {},
  toggleMemo: () => {},
  undo: () => {},
  hint: () => {},
  hintCount: 0,
  checkAnswer: () => {},
  displayTime: "00:00",
  paused: false,
  stopwatchStart: () => {},
  stopwatchPause: () => {},
  solved: false,
  historyArray: [],
  memoMode: false,
  toggleMemoMode: () => {},
  isShareModalOpen: false,
  openShareModal: () => {},
  closeShareModal: () => {},
  isAccountLinkModalOpen: false,
  closeAccountLinkModal: () => {},
  isHowToPlayModalOpen: false,
  openHowToPlayModal: () => {},
  closeHowToPlayModal: () => {},
  isAllFilldOnce: false,
  allHint: () => {},
  resultArray: [],
  shareText: "",
  nextDate: null,
  missCount: 0,
  dailyChallengeNumber: "",
  alreadyFinished: false,
  handleOperation: () => {},
  secondsFromLastOpration: 0,
  boardAddClassName: "",
  Confetti: null,
})

type NumberCell = string
type MemoCell = {
  1: boolean
  2: boolean
  3: boolean
  4: boolean
  5: boolean
  6: boolean
  7: boolean
  8: boolean
  9: boolean
}
type Cell = NumberCell | MemoCell

type Board = Cell[]

type History = {
  board: Board
  selectedCellIndex: number
}

const compareArrays = (array1: Cell[], array2: Cell[]): boolean => {
  if (array1.length != array2.length) {
    return false
  }
  for (let i = 0; i < array1.length; i++) {
    if (array1[i] !== array2[i]) {
      return false
    }
  }
  return true
}

const checkCorrect = (currentBoard: Board, isMini: boolean): boolean => {
  const colNum = isMini ? 6 : 9
  const rowNum = isMini ? 6 : 9
  const blockColNum = 3
  const blockRowNum = isMini ? 2 : 3

  const emptyArray = isMini ? [[], [], [], [], [], []] : [[], [], [], [], [], [], [], [], []]
  const compareArray = isMini
    ? ["1", "2", "3", "4", "5", "6"]
    : ["1", "2", "3", "4", "5", "6", "7", "8", "9"]

  // 行毎に分ける
  const rows = currentBoard.reduce((previousValue, cell, i) => {
    previousValue[Math.floor(i / colNum)].push(cell)
    return previousValue
  }, clone(emptyArray))
  // 全ての行に1-9が入っているか
  if (!rows.every((row) => compareArrays(row.slice().sort(), compareArray))) {
    //console.log("行間違いあり")
    //console.log(rows)
    return false
  }

  // 列毎に分ける
  const cols = currentBoard.reduce((previousValue, cell, i) => {
    previousValue[i % rowNum].push(cell)
    return previousValue
  }, clone(emptyArray))
  // 全ての列に1-9が入っているか
  if (!cols.every((col) => compareArrays(col.slice().sort(), compareArray))) {
    //console.log("列間違いあり")
    //console.log(cols)
    return false
  }

  // ブロック毎に分ける
  const blocks = currentBoard.reduce((previousValue, cell, i) => {
    const blockRowIndex = Math.floor(Math.floor(i / rowNum) / blockRowNum) // 上から何番目のブロックか
    const blockColIndex = Math.floor((i % colNum) / blockColNum) // 左から何番目のブロックか
    const blockColsNum = isMini ? 2 : 3
    const blockNumber = blockRowIndex * blockColsNum + blockColIndex // ブロック番号

    previousValue[blockNumber].push(cell)
    return previousValue
  }, clone(emptyArray))
  // 全てのブロックに1-9が入っているか
  if (!blocks.every((blocks) => compareArrays(blocks.slice().sort(), compareArray))) {
    //console.log("ブロック間違いあり")
    //console.log(blocks)
    return false
  }

  return true
}

export const NumberPlaceBoardProvider = ({
  numberPlaceBoard,
  isDailyChallengeMode,
  nextDate,
  alreadyFinished,
  finishedBoardArray,
  finishedHintCellIndexArray,
  stopwatchOffset,
  initialIsAccountLinkModalOpen,
  initialIsHowToPlayModalOpen,
  autoStart,
  children,
}: {
  numberPlaceBoard: NumberPlaceBoard
  isDailyChallengeMode: boolean
  nextDate: Date
  alreadyFinished: boolean
  finishedBoardArray?: string[]
  finishedHintCellIndexArray?: number[]
  stopwatchOffset: Date
  initialIsAccountLinkModalOpen: boolean
  initialIsHowToPlayModalOpen: boolean
  autoStart: boolean
  children: React.ReactNode
}) => {
  const {
    hashId,
    isMini,
    mode,
    modeParam,
    dailyChallengeNumber,
    dailyChallengeDate,
    dailyChallengeDateForDisp,
    initBoardArray,
    solvedBoardArray,
  } = numberPlaceBoard

  const [numberSelected, setNumberSelected] = useState<string>("0")
  const [currentBoard, setCurrentBoardArray] = useState<Board>(finishedBoardArray || initBoardArray)
  const [hintCellIndexArray, setHintCellIndexArray] = useState<number[]>(
    finishedHintCellIndexArray || []
  )
  const [hintCount, setHintCount] = useState<number>(finishedHintCellIndexArray?.length || 0)
  const [selectedCellIndex, setSelectedCellIndex] = useState<number>(0)
  const [solved, setSolved] = useState<boolean>(alreadyFinished)
  const [historyArray, setHistoryArray] = useState<History[]>([])
  const [memoMode, setMemoMode] = useState<boolean>(false)
  const [isShareModalOpen, setIsShareModalOpen] = useState<boolean>(false)
  const [isAccountLinkModalOpen, setIsAccountLinkModalOpen] = useState<boolean>(
    initialIsAccountLinkModalOpen
  )
  const [isHowToPlayModalOpen, setIsHowToPlayModalOpen] = useState<boolean>(
    initialIsHowToPlayModalOpen
  )
  const [resultArray, setResultArray] = useState<(string | boolean)[]>([])
  const [shareText, setShareText] = useState<string>("")
  const [missCount, setMissCount] = useState<number>(0)

  // ストップウォッチ関連
  const stopwatch = useStopwatch({ autoStart, offsetTimestamp: stopwatchOffset })

  const hourStr = stopwatch.hours > 0 ? `00${stopwatch.hours}`.slice(-2) : ""
  const minStr = `00${stopwatch.minutes}`.slice(-2)
  const secStr = `00${stopwatch.seconds}`.slice(-2)
  const displayTime = `${hourStr ? hourStr + ":" : ""}${minStr}:${secStr}`

  const paused = !stopwatch.isRunning && !solved

  const stopwatchStart = () => {
    if (!solved) {
      stopwatch.start()
    }
  }
  const stopwatchPause = stopwatch.pause

  const secondsFromStart = stopwatch.hours * 60 * 60 + stopwatch.minutes * 60 + stopwatch.seconds
  const [secondsLastOpration, setSecondsLastOpration] = useState<number>(secondsFromStart)
  const handleOperation = () => {
    setSecondsLastOpration(secondsFromStart)
  }
  const secondsFromLastOpration = secondsFromStart - secondsLastOpration

  // クリア後のアニメーション
  const { fire, Confetti } = useConfetti()

  const [boardAddClassName, setBoardAddClassName] = useState("")

  // LocalStorageのデータをサーバーに送信する
  const sendAllLocalStorageData = async () => {
    // ローカルストレージからデータを取得
    if (typeof window.localStorage !== "undefined") {
      try {
        const dataSubmittedKey = "numplay_submitted"
        const submitted = !!localStorage.getItem(dataSubmittedKey)

        if (!submitted) {
          const numplay_daily_results =
            JSON.parse(localStorage.getItem("numplay_daily_results")) || {}
          const numplay_daily6x6_results =
            JSON.parse(localStorage.getItem("numplay_daily6x6_results")) || {}
          const numplay_dailyeasy_results =
            JSON.parse(localStorage.getItem("numplay_dailyeasy_results")) || {}
          const numplay_results = JSON.parse(localStorage.getItem("numplay_results")) || {}

          await fetchApi(
            "/numplay/all_result_data",
            "POST",
            JSON.stringify({
              numplay_daily_results,
              numplay_daily6x6_results,
              numplay_dailyeasy_results,
              numplay_results,
            })
          )
          //localStorage.setItem(dataSubmittedKey, "true")
        }
      } catch (e) {
        console.error(e)
      }
    }
  }

  useEffect(() => {
    sendAllLocalStorageData()
  }, [])

  // LocalStorageのデータをサーバーに送信する
  const sendResultData = async (resultData: {
    id: string
    boardArray: (string | string[])[]
    hintCellIndexArray: number[]
    finishedSeconds: number
    missCount: number
    hintCount: number
  }) => {
    try {
      await fetchApi(
        "/numplay/result_data",
        "POST",
        JSON.stringify({
          result_data: resultData,
        })
      )
    } catch (e) {
      console.error(e)
    }
  }

  // 答え合わせしたらシェアテキストを生成、2秒後にシェアモーダルを開く
  useEffect(() => {
    // 解決済み
    if (solved) {
      const isCorrect = checkCorrect(currentBoard, isMini)

      let result: (string | boolean)[]
      if (isCorrect) {
        result = initBoardArray.map((initNumber, i) => {
          if (initNumber !== "0") {
            return initNumber
          } else {
            return true
          }
        })
      } else {
        // 最初から埋まってるセルは数字、正解ならtrue、不正解ならfalse の配列を生成
        result = initBoardArray.map((initNumber, i) => {
          if (initNumber !== "0") {
            return initNumber
          } else if (solvedBoardArray[i] === currentBoard[i]) {
            return true
          } else {
            return false
          }
        })
      }
      setResultArray(result)

      // 不正解の数をカウント
      const missCount = result.filter((cell) => cell === false).length
      setMissCount(missCount)

      // ヒントの数をカウント
      const hintCount = hintCellIndexArray.length
      setHintCount(hintCount)

      // シェアテキストを生成
      const shareText = result
        .map((cell, i) => {
          let str = ""
          if (typeof cell === "string") {
            str += cell + "️" + "⃣"
          } else {
            str += cell ? "🟩" : "🟥"
          }
          if (isMini ? i % 6 === 5 : i % 9 === 8) {
            str += "\n"
          }
          return str
        })
        .join("")

      if (!alreadyFinished) {
        // 完了秒数
        const finishedSeconds = stopwatch.hours * 360 + stopwatch.minutes * 60 + stopwatch.seconds

        if (isDailyChallengeMode) {
          // 今日のデイリーチャレンジの場合は Cookie をセット
          // cookie セット
          // ボード
          Cookies.set(
            `numplay_${hashId}_finished_board`,
            currentBoard.map((cell) => (typeof cell === "string" ? cell : "0")).join(""), // メモは削除
            { expires: nextDate }
          )
          // ヒント
          Cookies.set(
            `numplay_${hashId}_finished_hint_cell_index`,
            hintCellIndexArray.join(","), // カンマで連結
            { expires: nextDate }
          )
          // 完了秒数
          Cookies.set(`numplay_${hashId}_finished_seconds`, finishedSeconds.toString(), {
            expires: nextDate,
          })
        }

        // ローカルストレージ保存
        if (typeof window.localStorage !== "undefined") {
          const resultsKey = `numplay${dailyChallengeDate ? `_daily${modeParam}_` : "_"}results`
          let results = {}
          try {
            results = JSON.parse(localStorage.getItem(resultsKey))
          } catch (e) {
            console.error(e)
          }
          const keyId = dailyChallengeDate || hashId
          const oldResult = (results || {})[keyId]

          // ベストタイム(ノーミス、ノーヒント)
          const oldNoMissNoHintFinishedSeconds =
            oldResult?.bestTime?.noMissNoHint?.finishedSeconds || null
          const noMissNoHint =
            missCount === 0 && hintCount === 0
              ? typeof oldNoMissNoHintFinishedSeconds === "number"
                ? { finishedSeconds: Math.min(finishedSeconds, oldNoMissNoHintFinishedSeconds) }
                : { finishedSeconds }
              : oldResult?.bestTime?.noMissNoHint || null

          // ベストタイム(ノーミス)
          const oldNoMissFinishedSeconds = oldResult?.bestTime?.noMiss?.finishedSeconds || null
          const noMiss =
            missCount === 0
              ? typeof oldNoMissFinishedSeconds === "number"
                ? { finishedSeconds: Math.min(finishedSeconds, oldNoMissFinishedSeconds) }
                : { finishedSeconds }
              : oldResult?.bestTime?.noMiss || null

          // ベストタイム(全て)
          const oldAllBestTimeFinishedSeconds = oldResult?.bestTime?.all?.finishedSeconds || null
          const allBestTime =
            typeof oldAllBestTimeFinishedSeconds === "number"
              ? Math.min(finishedSeconds, oldAllBestTimeFinishedSeconds) === finishedSeconds
                ? { finishedSeconds, missCount, hintCount }
                : oldResult?.bestTime?.all
              : { finishedSeconds, missCount, hintCount }

          const newResults = JSON.stringify({
            ...results,
            [keyId]: {
              id: hashId,
              // メモオブジェクトを配列に変換,
              // { 1: true, 2: false, 3: true } => [1, 3]
              boardArray: currentBoard.map((cell) =>
                typeof cell === "object"
                  ? Object.keys(cell).filter((key) => cell[key] === true)
                  : cell
              ),
              hintCellIndexArray: hintCellIndexArray,
              finishedSeconds,
              missCount: missCount,
              hintCount: hintCellIndexArray.length,
              bestTime: {
                noMissNoHint,
                noMiss,
                all: allBestTime,
              },
            },
          })
          localStorage.setItem(resultsKey, newResults)
        }

        // サーバー送信用
        const resultData = {
          id: hashId,
          // メモのオブジェクトを配列に変換,
          // { 1: true, 2: false, 3: true } => [1, 3]
          boardArray: currentBoard.map((cell) =>
            typeof cell === "object" ? Object.keys(cell).filter((key) => cell[key] === true) : cell
          ),
          hintCellIndexArray: hintCellIndexArray,
          finishedSeconds,
          missCount: missCount,
          hintCount: hintCellIndexArray.length,
        }
        sendResultData(resultData)

        setTimeout(() => {
          if (missCount === 0) {
            fire()
          } else {
            setBoardAddClassName("animate-shake-horizontal")
          }
        }, 2000)

        setTimeout(() => {
          setShareText(shareText)
          setIsShareModalOpen(true)
        }, 3000)
      } else {
        setShareText(shareText)
      }
    }
  }, [alreadyFinished, solved])

  // 一度でも全埋めしたか (自動で答え合わせ確認モーダルを開く用)
  const [isAllFilldOnce, setIsAllFilldOnce] = useState<boolean>(false)
  const isAllFilled = currentBoard.every((cell) => typeof cell === "string" && cell !== "0")
  useEffect(() => {
    if (!isAllFilldOnce && isAllFilled) {
      setIsAllFilldOnce(true)
    }
  }, [isAllFilldOnce, isAllFilled])

  /**
   * ゲームリセット
   */
  const resetGame = () => {
    setCurrentBoardArray(initBoardArray)
    setNumberSelected("0")
    stopwatch.reset()
    setSelectedCellIndex(0)
    setHistoryArray([])
    setIsAllFilldOnce(false)
  }

  /**
   * ゲームクリアかチェック
   */
  const checkAnswer = () => {
    setSolved(true)
  }

  /**
   * マスを埋める、消す、メモ
   */
  const _fillCell = (index: number, newCell: Cell) => {
    // セルが選択されていない or 元から埋まってるセル の場合
    if (initBoardArray[index] !== "0") {
      return
    }

    // ボードを更新
    const tempBoard = clone(currentBoard)
    tempBoard[index] = newCell
    setCurrentBoardArray(tempBoard)

    // ヒストリーを更新
    const tempHistoryArray = clone(historyArray)
    const lastHistory: History = {
      board: tempBoard,
      selectedCellIndex: index,
    }
    tempHistoryArray.push(lastHistory)
    setHistoryArray(tempHistoryArray)
  }

  /**
   * 数字で埋める
   */
  const fillNumber = (number: string) => {
    // 現在の値
    const currentCell = currentBoard[selectedCellIndex]

    // 現在の値と新しい値が同じ場合
    if (typeof currentCell === "string" && currentCell === number) {
      return
    }

    _fillCell(selectedCellIndex, number)
  }

  /**
   * メモを追加
   */
  const toggleMemo = (number: string) => {
    // 現在の値
    const currentCell = currentBoard[selectedCellIndex]

    if (typeof currentCell === "string") {
      // 現在の値が数字の場合、新しくメモを追加
      const newCell = {
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
        6: false,
        7: false,
        8: false,
        9: false,
      }
      newCell[number] = true
      _fillCell(selectedCellIndex, newCell)
    } else {
      // 現在の値が数字でない場合、新しくメモを追加
      const newCell = clone(currentCell)

      // メモの true/false を反転
      newCell[number] = !newCell[number]

      if (
        Object.keys(newCell)
          .map((key) => newCell[key])
          .filter((val) => val).length === 0
      ) {
        // 全部falseの場合、"0"をセット
        _fillCell(selectedCellIndex, "0")
      } else {
        _fillCell(selectedCellIndex, newCell)
      }
    }
  }

  /**
   * ヒント
   */
  const hint = () => {
    // 最初から埋まってるマスではない、かつ、ヒントで埋めたマスでない場合
    if (initBoardArray[selectedCellIndex] === "0") {
      // ボードを更新
      _fillCell(selectedCellIndex, solvedBoardArray[selectedCellIndex])

      // ヒントを使用したセル番号リストに含まれていない場合(初回)
      if (!hintCellIndexArray.includes(selectedCellIndex)) {
        // ヒントを使用したセル番号リストに追加
        setHintCellIndexArray([...hintCellIndexArray, selectedCellIndex])

        // GAイベント
        //window.dataLayer = window.dataLayer || []
        //window.dataLayer.push({
        //  event: "numplay_hint",
        //  event_params: {
        //    action: "numplay_hint",
        //    label: "",
        //  },
        //})
      }
    }
  }

  /**
   * 管理者用：全マス埋める
   */
  const allHint = () => {
    // ボードを更新
    setCurrentBoardArray(solvedBoardArray)

    // ヒストリーを更新
    const tempHistoryArray = clone(historyArray)
    const lastHistory: History = {
      board: solvedBoardArray,
      selectedCellIndex: 0,
    }
    tempHistoryArray.push(lastHistory)
    setHistoryArray(tempHistoryArray)
  }

  /**
   * 元に戻す
   */
  const undo = () => {
    if (historyArray.length) {
      const tempHistoryArray = historyArray.slice()
      const currentHistory = tempHistoryArray.pop()
      setHistoryArray(tempHistoryArray)

      const lastHistory = tempHistoryArray.slice(-1)[0]
      if (lastHistory) {
        setCurrentBoardArray(lastHistory.board)
      } else {
        setCurrentBoardArray(initBoardArray)
      }
      if (currentHistory) {
        setSelectedCellIndex(currentHistory.selectedCellIndex)
      } else {
        setSelectedCellIndex(0)
      }
    }
  }

  const toggleMemoMode = () => {
    setMemoMode(!memoMode)
  }

  return (
    <NumberPlaceBoardContext.Provider
      value={{
        isDailyChallengeMode,
        isMini,
        mode,
        modeParam,
        dailyChallengeDateForDisp,
        hashId,
        numberSelected,
        setNumberSelected,
        currentBoard,
        selectedCellIndex,
        setSelectedCellIndex,
        initBoardArray,
        solvedBoardArray,
        historyArray,
        solved,
        resetGame,
        fillNumber,
        toggleMemo,
        undo,
        hint,
        hintCount,
        checkAnswer,
        displayTime,
        paused,
        stopwatchStart,
        stopwatchPause,
        memoMode,
        toggleMemoMode,
        isShareModalOpen,
        openShareModal: () => {
          setIsShareModalOpen(true)
        },
        closeShareModal: () => {
          setIsShareModalOpen(false)
        },
        isAccountLinkModalOpen,
        closeAccountLinkModal: () => {
          setIsAccountLinkModalOpen(false)
          stopwatchStart()
        },
        isHowToPlayModalOpen,
        openHowToPlayModal: () => {
          setIsHowToPlayModalOpen(true)
          stopwatchPause()
        },
        closeHowToPlayModal: () => {
          setIsHowToPlayModalOpen(false)
          stopwatchStart()
        },
        isAllFilldOnce,
        allHint,
        resultArray,
        shareText,
        nextDate,
        missCount,
        dailyChallengeNumber,
        alreadyFinished,
        handleOperation,
        secondsFromLastOpration,
        boardAddClassName,
        Confetti,
      }}
    >
      {children}
    </NumberPlaceBoardContext.Provider>
  )
}

export const useNumberPlaceBoardContext = (): NumberPlaceBoardContextProps =>
  useContext(NumberPlaceBoardContext)
