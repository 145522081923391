import React, { useState } from "react"
import Button from "./Button"
import TwitterIcon from "./icons/Twitter"

import firebase, { firebaseError } from "../lib/firebase"
import { CheckIcon } from "@heroicons/react/solid"

type LoginButtonPanelProps = {
  isSignup?: boolean
  redirectUrl?: string
  onClickLoginButton?: () => void
}

export const LoginButtonPanel: React.FC<LoginButtonPanelProps> = ({
  isSignup = false,
  redirectUrl,
  onClickLoginButton,
}) => {
  const [termChecked, setTermChecked] = useState(true)
  return (
    <>
      <div className="mb-4">
        <button
          className="focus:outline-none relative inline-flex w-full cursor-pointer select-none items-center justify-center rounded border border-transparent bg-blue-500 py-1.5 pl-2 pr-4 text-white hover:bg-blue-600 focus:shadow-outline-blue disabled:cursor-not-allowed disabled:bg-blue-500 disabled:opacity-50 touch-hover:bg-blue-500"
          onClick={() => {
            onClickLoginButton?.()
            if (redirectUrl) {
              // リダイレクトURLを指定する
              window.history.pushState({}, "", redirectUrl)
            }
            const provider = new firebase.auth.TwitterAuthProvider()
            firebase.auth().signInWithRedirect(provider)
          }}
          disabled={!termChecked}
        >
          <span className="pl-1 pr-2">
            <TwitterIcon className="w-4 h-4" />
          </span>
          <span>Twitterアカウントで{isSignup ? "登録" : "ログイン"}</span>
        </button>
      </div>
      <div className="flex items-center justify-center text-xs">
        <div
          className={`flex h-4 w-4 bg-white cursor-pointer select-none items-center justify-center rounded-sm border border-gray-300`}
          onClick={() => {
            setTermChecked(!termChecked)
          }}
        >
          {termChecked && <CheckIcon className="h-3.5 w-3.5 text-black" />}
        </div>
        <div className="ml-2">
          <a href="/terms" target="_blank" rel="nofollow" className="text-blue-500">
            利用規約
          </a>
          に同意した上で{isSignup ? "登録" : "ログイン"}する。
        </div>
      </div>
    </>
  )
}
