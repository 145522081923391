import React from "react"

class Thumb extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      imageUrl: props.initialImageUrl,
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.file !== nextProps.file) {
      if (nextProps.file) {
        this.setState({ loading: true }, () => {
          let reader = new FileReader()
          reader.onloadend = () => {
            this.setState({ loading: false, imageUrl: reader.result })
          }
          reader.readAsDataURL(nextProps.file)
        })
      } else {
        this.setState({ loading: false, imageUrl: this.props.initialImageUrl })
      }
    }
  }

  render() {
    const { file } = this.props
    const { loading, imageUrl } = this.state

    if (!file && !imageUrl) {
      return null
    }

    if (
      imageUrl === "/images/fallback/default.png" ||
      imageUrl === "/images/fallback/img_default.png" ||
      imageUrl === "/images/fallback/half_default.png"
    ) {
      return null
    }

    if (loading) {
      return <p>loading...</p>
    }

    return (
      <img
        src={imageUrl}
        alt={file && file.name}
        className="block mb-2 border"
        height={200}
        width={200}
      />
    )
  }
}

export default Thumb
