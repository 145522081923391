import React, { useEffect, useState } from "react"

import { fetchApi } from "../src/lib/fetcher"

import Label from "../src/components/Label"
import TextField from "../src/components/TextField"
import Button from "../src/components/Button"

import { Formik } from "formik"
import userProfileValidationSchema from "../src/validations/userProfileValidationSchema"

export default function EditProfileForm({ currentUser, isNew }) {
  const [user, setUser] = useState(currentUser)
  const [avatarImageUrl, setAvatarImageUrl] = useState(null)

  const pushNextPage = () => {
    const params = new URL(document.location).searchParams
    const nextUrl = params && params.get("next")
    window.location.href = nextUrl || "/"
  }

  const handleOnSubmit = async (values, actions) => {
    const { avatarImageDataUrl, display_name } = values

    try {
      const data = await fetchApi(
        "/setting/profile",
        "POST",
        JSON.stringify({
          avatarImageDataUrl,
          display_name,
        })
      )

      // userをセット
      setUser(data.user)

      // トースト表示 TODO
      //dispatch({ type: "ADD_TOAST", payload: { id: Math.random(), type: 'success', text: 'プロフィールを保存しました' } })

      // 次のページがあれば遷移
      pushNextPage()
    } catch (error) {
      if (error.data && error.data.errors) {
        actions.setErrors({
          ...error.data.errors,
          general: error.data.message || "入力エラーがあります",
        })
      } else {
        actions.setFieldError("general", "エラーが発生しました")
      }
    } finally {
      actions.setSubmitting(false)
    }
  }

  return (
    <Formik
      initialValues={{ avatarImageDataUrl: null, display_name: user.display_name }}
      validationSchema={userProfileValidationSchema}
      onSubmit={(values, actions) => {
        handleOnSubmit(values, actions)
      }}
    >
      {({
        values,
        errors,
        status,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        setFieldTouched,
      }) => (
        <form>
          <input type="text" className="hidden" />
          <div className="flex mb-4 -mx-2">
            <div className="flex items-center justify-end w-1/4 px-2">
              <Label>アイコン</Label>
            </div>
            <div className="flex items-center w-3/4 px-2">
              <label className="inline-flex items-center cursor-pointer hover:opacity-75">
                <input
                  id="avatarImageFile"
                  type="file"
                  accept="image/*"
                  onChange={(event) => {
                    const file = event.currentTarget.files[0]
                    if (file) {
                      const reader = new FileReader()
                      reader.onloadend = () => {
                        const imgReader = new Image()
                        imgReader.onload = () => {
                          const canvasSize = 256
                          const imgRatio = imgReader.width / imgReader.height //画像の比率を取得
                          const imgPosX =
                            imgRatio >= 1 ? (canvasSize - canvasSize * imgRatio) / 2 : 0
                          const imgPosY =
                            imgRatio >= 1 ? 0 : (canvasSize - canvasSize / imgRatio) / 2
                          const imgWidth = imgRatio >= 1 ? canvasSize * imgRatio : canvasSize
                          const imgHeight = imgRatio >= 1 ? canvasSize : canvasSize / imgRatio

                          const canvas = document.createElement("canvas")
                          canvas.width = canvasSize
                          canvas.height = canvasSize
                          const ctx = canvas.getContext("2d")
                          ctx.drawImage(imgReader, imgPosX, imgPosY, imgWidth, imgHeight)

                          const dataUrl = canvas.toDataURL("image/jpeg")
                          setFieldValue("avatarImageDataUrl", dataUrl)
                          setAvatarImageUrl(dataUrl)
                        }
                        imgReader.src = reader.result
                      }
                      reader.readAsDataURL(file)
                    }
                  }}
                  className="hidden"
                />
                <img
                  src={avatarImageUrl || user.avatar_image}
                  className="object-cover w-12 h-12 border rounded-full"
                />
                <span className="ml-3 text-blue-500">画像を選択</span>
              </label>
              {values.avatarImageDataUrl !== null && (
                <>
                  <span className="px-4 text-gray-200">|</span>
                  <a
                    className="text-gray-400 cursor-pointer hover:opacity-75"
                    onClick={() => {
                      setFieldValue("avatarImageDataUrl", null)
                      setAvatarImageUrl(null)
                      // リセット後同じファイルを選択時にonChangeが呼ばれるようにinputもリセット
                      document.getElementById("avatarImageFile").value = ""
                    }}
                  >
                    リセット
                  </a>
                </>
              )}
            </div>
          </div>

          <div className="flex mb-4 -mx-2">
            <div className="flex items-center justify-end w-1/4 px-2">
              <Label htmlFor="display_name">名前</Label>
            </div>
            <div className="w-3/4 px-2">
              <TextField id="display_name" name="display_name" placeholder="名前" />
            </div>
          </div>

          <div className="mb-4 sm:flex sm:-mx-2">
            <div className="sm:w-1/4 sm:px-2"></div>
            <div className="text-center sm:text-left sm:w-3/4 sm:px-2">
              <div className="sm:flex sm:items-center">
                <Button
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                  loading={isSubmitting}
                  rounded={true}
                  color="blue"
                >
                  保存する
                </Button>
                <div className="my-4 sm:ml-4 sm:my-0">
                  {isNew && (
                    <a
                      className="text-gray-500 underline cursor-pointer hover:opacity-75"
                      onClick={() => {
                        pushNextPage()
                      }}
                    >
                      スキップ
                    </a>
                  )}
                </div>
              </div>
              {isNew && (
                <p className="mt-4 text-xs text-gray-500">※あとでマイページから変更できます。</p>
              )}
              {errors.general && <div className="mt-4 text-red-500">{errors.general}</div>}
            </div>
          </div>
        </form>
      )}
    </Formik>
  )
}
