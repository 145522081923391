import React from "react"
import { FieldHookConfig, useField } from "formik"

type TextAreaFieldProps = {
  max?: number
} & FieldHookConfig<string>

export const TextAreaField = ({ max, ...props }: TextAreaFieldProps) => {
  const [field, meta] = useField(props)
  const hasError = meta.touched && meta.error
  const inputClassName = `block appearance-none text-base bg-white border border-gray-200 rounded w-full py-1.5 px-2.5 focus:outline-none focus:border-gray-500 ${
    hasError ? "border-red-500" : ""
  }`
  return (
    <>
      <textarea {...field} className={inputClassName} />
      <div className="flex justify-between">
        <div>{hasError && <div className="mt-1 text-xs text-red-500">{meta.error}</div>}</div>
        {max && (
          <div className={`text-xs mt-1 ${field.value.length > max && "text-red-500"}`}>
            {field.value.length}/{max}
          </div>
        )}
      </div>
    </>
  )
}
