import React from "react"
import { useIntl } from "react-intl"
import { classNames } from "../../src/utils/classNames"
import { useNumberPlaceBoardContext } from "../context/NumberPlaceBoardContext"
import { Button } from "./Button"
import { Modal } from "../../src/components/Modal"

export const HowToPlayModal = () => {
  const { isMini, mode, isHowToPlayModalOpen, closeHowToPlayModal } = useNumberPlaceBoardContext()

  const intl = useIntl()
  const title = intl.formatMessage({ id: `title_${mode}` })

  return (
    <>
      <Modal
        isOpen={isHowToPlayModalOpen}
        onClose={() => {
          closeHowToPlayModal()
        }}
      >
        <div className="p-4">
          <div className="mb-4">
            <div className="mb-2 font-bold texl-base">
              ■{intl.formatMessage({ id: "How to Play {title}" }, { title })}
            </div>
            <div className="text-xs">
              <div>
                {intl.formatMessage(
                  {
                    id: "{title} is one of the number puzzle games called Sudoku.",
                    defaultMessage: " ",
                  },
                  { title }
                )}
              </div>
              <div>
                {intl.formatMessage({
                  id: "Guess the numbers in the blank squares and fill in the numbers.",
                  defaultMessage: " ",
                })}
              </div>
            </div>
          </div>
          <div className="mb-4">
            <div className="mb-2 font-bold texl-base">■{intl.formatMessage({ id: "Rules" })}</div>
            <div className="text-xs">
              <div className="flex items-center gap-2 mb-4">
                <div>①</div>
                <div className="flex-none w-1/4">
                  <img src={`/images/numplay/rule_${isMini ? "6x6" : "9x9"}_1.png`} />
                </div>
                <div>
                  {intl.formatMessage(
                    {
                      id: "Every horizontal and vertical row must contain each number from 1 to {maxNum}.",
                    },
                    { maxNum: isMini ? "6" : "9" }
                  )}
                </div>
              </div>
              <div className="flex items-center gap-2 mb-4">
                <div>②</div>
                <div className="flex-none w-1/4">
                  <img src={`/images/numplay/rule_${isMini ? "6x6" : "9x9"}_2.png`} />
                </div>
                <div>
                  {intl.formatMessage(
                    {
                      id: "Within the {blockNxN} frame, you must also find each number from 1 to {maxNum}.",
                    },
                    { blockNxN: isMini ? "2x3" : "3x3", maxNum: isMini ? "6" : "9" }
                  )}
                </div>
              </div>
              <div className="mb-2">
                {intl.formatMessage({ id: "Use the numbers already displayed as hints." })}
              </div>
              <div>
                {intl.formatMessage({
                  id: "Complete all the blank squares using the Notes and Hint options if needed.",
                })}
              </div>
              <hr className="my-4" />
              <div>
                {intl.formatMessage(
                  { id: "A new {title} will be released daily at midnight." },
                  { title }
                )}
              </div>
            </div>
          </div>
          <div>
            <Button
              onClick={() => {
                closeHowToPlayModal()
              }}
              className="w-full text-black !font-normal text-sm"
            >
              {intl.formatMessage({ id: "Close" })}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  )
}
