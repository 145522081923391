import React from "react"
import { classNames } from "../../src/utils/classNames"
import { useNumberPlaceBoardContext } from "../context/NumberPlaceBoardContext"

import { Modal } from "../../src/components/Modal"
import { AccountLinkForm } from "../../src/components/AccountLinkForm"
import Button from "../../src/components/Button"

export const AccountLinkModal = () => {
  const { isMini, mode, isAccountLinkModalOpen, closeAccountLinkModal } =
    useNumberPlaceBoardContext()

  return (
    <>
      <Modal isOpen={isAccountLinkModalOpen} onClose={closeAccountLinkModal}>
        <div className="p-4">
          <AccountLinkForm />
          <div className="mt-4 text-center">
            <Button
              onClick={closeAccountLinkModal}
              size={undefined}
              rounded={undefined}
              fullwidth={undefined}
              leftIcon={undefined}
              rightIcon={undefined}
              loaderColor={undefined}
            >
              連携せずに遊ぶ
            </Button>
          </div>
        </div>
      </Modal>
    </>
  )
}
