import React, { MutableRefObject } from "react"
import { Dialog } from "@headlessui/react"
import { classNames } from "../utils/classNames"
import { AnimatePresence, motion, MotionConfig } from "framer-motion/dist/framer-motion"
import { FiX } from "react-icons/fi"

type ModalProps = {
  isOpen: boolean
  onClose?: () => void
  initialFocus?: MutableRefObject<HTMLElement | null>
  containerClassNames?: string
  overlayClassNames?: string
  useDefaultContainer?: boolean
  children: React.ReactNode
}

export const Modal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  initialFocus,
  containerClassNames = "fixed inset-0 z-50 overflow-y-auto",
  overlayClassNames = "bg-black/20",
  useDefaultContainer = true,
  children,
}) => {
  return (
    <MotionConfig transition={{ type: "tween", duration: 0.2 }}>
      <AnimatePresence>
        {isOpen && (
          <Dialog
            static
            open
            className={containerClassNames}
            onClose={() => onClose?.()}
            initialFocus={initialFocus}
          >
            <Dialog.Overlay
              as={motion.div}
              className={classNames("fixed inset-0", overlayClassNames)}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            />
            {useDefaultContainer ? (
              <div className="flex items-center justify-center min-h-full px-4">
                <motion.div
                  className="relative w-full max-w-md my-8 overflow-hidden bg-white rounded-2xl"
                  initial={{ opacity: 0, scale: 0.95 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0.95 }}
                >
                  <button
                    onClick={onClose}
                    className="absolute outline-none top-2 right-2 focus-visible:ring-2"
                  >
                    <FiX className="w-6 h-6 text-gray-500" />
                  </button>
                  {children}
                </motion.div>
              </div>
            ) : (
              children
            )}
          </Dialog>
        )}
      </AnimatePresence>
    </MotionConfig>
  )
}
