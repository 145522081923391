import React, { useState } from "react"
import { classNames } from "../src/utils/classNames"
import { IntlProvider, useIntl } from "react-intl"
import { messages } from "../src/utils/i18n"
import { HiChevronRight } from "react-icons/hi"

export type NumberPlaceBoard = {
  hashId: string
  mode: string
  modeParam: string
  isMini: boolean
  dailyChallengeDate: string
  dailyChallengeDateForDisp: string
  initBoardArray: string[]
}

const getDateStr = (date: Date) => {
  return `${date.getFullYear()}-${`0${date.getMonth() + 1}`.slice(-2)}-${`0${date.getDate()}`.slice(
    -2
  )}`
}

const NumplayTopLinkApp = ({
  numplayList,
  locale,
}: {
  numplayList: Record<string, NumberPlaceBoard>[]
  locale: string
}) => {
  // 今日
  const [today] = useState<Date>(new Date())
  const todayStr = getDateStr(today)

  return (
    <IntlProvider locale={locale} messages={messages[locale].numplay}>
      <div className="grid grid-cols-2 gap-6 mb-4">
        {numplayList
          .map((list) => list[todayStr] || list[Object.keys(list)[0]])
          .map((board) => (
            <div>
              <Board board={board} />
            </div>
          ))}
      </div>
    </IntlProvider>
  )
}

export default NumplayTopLinkApp

const Board = ({ board }: { board: NumberPlaceBoard }) => {
  const intl = useIntl()
  const rowNum = board.isMini ? 6 : 9
  const rows = board.isMini ? [0, 1, 2, 3, 4, 5] : [0, 1, 2, 3, 4, 5, 6, 7, 8]
  const title = intl.formatMessage({ id: `title_${board.mode}` })

  return (
    <>
      <h2 className="mb-4 text-lg font-bold text-center">{title}</h2>
      <table className="w-full font-mono text-xl border-2 border-black">
        <tbody>
          {rows.map((row) => {
            return (
              <tr
                className={classNames(
                  (row + 1) % (board.isMini ? 2 : 3) === 0 && "border-b-2 border-black"
                )}
                key={row}
              >
                {rows.map((column) => {
                  const cellIndex = row * rowNum + column
                  const cell = board.initBoardArray[cellIndex]
                  const textClass = cell !== "0" ? "text-[#334861]" : "text-transparent"

                  return (
                    <td
                      key={cellIndex}
                      className={classNames(
                        "relative border border-gray-300 select-none before:block before:pt-[100%]",
                        board.isMini ? "w-1/6" : "w-[11.11%]",
                        (column + 1) % 3 === 0 && "border-r-2 border-r-black"
                      )}
                    >
                      <div
                        className={classNames(
                          "absolute inset-0 flex items-center justify-center text-[calc(min(20px,(100vw-40px)/2/11*0.8))]",
                          textClass
                        )}
                      >
                        {cell}
                      </div>
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
      <div className="mt-4 space-y-4">
        <a
          href={`/numplay${board.modeParam}`}
          className="relative flex items-center justify-center py-2 text-base text-center text-white bg-blue-500 rounded-lg"
        >
          <span>
            {board.dailyChallengeDateForDisp}
            {intl.locale === "ja" && "の"}
            <br />
            {title}
          </span>
          <HiChevronRight className="absolute w-5 h-5 right-2" />
        </a>
        <a
          href={`/numplay${board.modeParam}/daily-challenge`}
          className="relative flex items-center justify-center py-2 text-base text-center text-blue-500 bg-gray-200 rounded-lg"
        >
          <span>
            {intl.formatMessage({ id: "Past" })}
            <br />
            {title}
          </span>
          <HiChevronRight className="absolute w-5 h-5 right-2" />
        </a>
      </div>
    </>
  )
}
