import React from "react"
import { FiCheck, FiCircle, FiX } from "react-icons/fi"
import { classNames } from "../../src/utils/classNames"
import { useNumberPlaceBoardContext } from "../context/NumberPlaceBoardContext"
import { motion } from "framer-motion/dist/framer-motion"
import { useIntl } from "react-intl"

/**
 * React component for the Game Section
 */
export const GameSection = () => {
  const {
    isMini,
    setSelectedCellIndex,
    currentBoard,
    resultArray,
    solvedBoardArray,
    selectedCellIndex,
    initBoardArray,
    paused,
    solved,
    alreadyFinished,
    boardAddClassName,
    Confetti,
  } = useNumberPlaceBoardContext()

  const intl = useIntl()

  const rowNum = isMini ? 6 : 9
  const rows = isMini ? [0, 1, 2, 3, 4, 5] : [0, 1, 2, 3, 4, 5, 6, 7, 8]

  /**
   * Cell Highlight Method 1: Highlight all cells
   * related to current cell. By related, I mean all
   * cells in the same row/column/box as the current cell.
   */
  // eslint-disable-next-line
  function _isCellRelatedToSelectedCell(row: number, column: number) {
    if (selectedCellIndex === row * rowNum + column) {
      return true
    }
    let rowOfSelectedCell = Math.floor(selectedCellIndex / rowNum)
    let columnOfSelectedCell = selectedCellIndex % rowNum
    if (rowOfSelectedCell === row || columnOfSelectedCell === column) {
      return true
    }
    return (
      isMini
        ? [
            [0, 2, 0, 3],
            [0, 2, 3, 6],
            [2, 4, 0, 3],
            [2, 4, 3, 6],
            [4, 6, 0, 3],
            [4, 6, 3, 6],
          ]
        : [
            [0, 3, 0, 3],
            [0, 3, 3, 6],
            [0, 3, 6, 9],
            [3, 6, 0, 3],
            [3, 6, 3, 6],
            [3, 6, 6, 9],
            [6, 9, 0, 3],
            [6, 9, 3, 6],
            [6, 9, 6, 9],
          ]
    ).some((array) => {
      if (
        rowOfSelectedCell > array[0] - 1 &&
        row > array[0] - 1 &&
        rowOfSelectedCell < array[1] &&
        row < array[1] &&
        columnOfSelectedCell > array[2] - 1 &&
        column > array[2] - 1 &&
        columnOfSelectedCell < array[3] &&
        column < array[3]
      )
        return true
      return false
    })
  }

  /**
   * Cell Highlight Method 2: Highlight all cells with
   * the same number as in the current cell.
   */
  function _isCellSameAsSelectedCell(row: number, column: number) {
    if (selectedCellIndex === row * rowNum + column) {
      return true
    }
    if (currentBoard[selectedCellIndex] === "0") {
      return false
    }
    if (currentBoard[selectedCellIndex] === currentBoard[row * rowNum + column]) {
      return true
    }
  }

  const totalCellsForCheck = initBoardArray.filter((cell) => cell === "0").length
  let cellIndexForCheck = 0 // 答え合わせ対象セルのうち何番目か

  return (
    <div className={classNames(paused && "opacity-30 pointer-events-none")}>
      <table
        className={classNames("w-full font-mono text-xl border-2 border-black", boardAddClassName)}
      >
        <tbody>
          {rows.map((row) => {
            return (
              <tr
                className={classNames(
                  (row + 1) % (isMini ? 2 : 3) === 0 && "border-b-2 border-black"
                )}
                key={row}
              >
                {paused ? (
                  <>
                    {rows.map((column) => {
                      const cellIndex = row * rowNum + column
                      return (
                        <td
                          key={cellIndex}
                          onClick={() => setSelectedCellIndex(cellIndex)}
                          className={classNames(
                            "relative border border-gray-300 select-none before:block before:pt-[100%]",
                            isMini ? "w-1/6" : "w-[11.11%]",
                            (column + 1) % 3 === 0 && "border-r-2 border-r-black"
                          )}
                        ></td>
                      )
                    })}
                  </>
                ) : (
                  <>
                    {rows.map((column) => {
                      const cellIndex = row * rowNum + column
                      const cell = currentBoard[cellIndex]
                      const isSelected = selectedCellIndex === cellIndex // 選択中のセルか
                      const isFilled = typeof cell === "string" && cell !== "0" // 埋められてるセルか
                      const isInitFilled = initBoardArray[cellIndex] !== "0" // 最初から埋められてるセルか
                      const isRelated = _isCellRelatedToSelectedCell(row, column) // 選択中のセルと関係のあるセルか（縦 / 横 / 同じブロック）
                      const isSameValue =
                        selectedCellIndex !== -1 && _isCellSameAsSelectedCell(row, column) // 選択中のセルと同じ値のセルか
                      cellIndexForCheck = isInitFilled ? cellIndexForCheck : cellIndexForCheck + 1

                      const bgClass = solved
                        ? "" // 解答済み
                        : isSelected
                        ? "bg-[#BBDEFB]" // 選択中のセル
                        : isSameValue
                        ? "bg-[#C3D7EA]" // 同じ値のセル
                        : isRelated
                        ? "bg-[#E2EBF3]" // 関連のセル
                        : ""
                      const textClass = isFilled
                        ? isInitFilled
                          ? "text-[#334861]" // 最初から埋められている
                          : "text-[#0072E2]" // 埋めた
                        : "text-transparent" // 埋められていない
                      return (
                        <td
                          key={cellIndex}
                          onClick={() => {
                            if (!solved) {
                              setSelectedCellIndex(cellIndex)
                            }
                          }}
                          className={classNames(
                            "relative w-[11.11%] border border-gray-300 select-none before:block before:pt-[100%]",
                            (column + 1) % 3 === 0 && "border-r-2 border-r-black",
                            bgClass
                          )}
                        >
                          {typeof cell === "string" ? (
                            <div
                              className={classNames(
                                "absolute inset-0 flex items-center justify-center",
                                textClass
                              )}
                            >
                              {cell}
                            </div>
                          ) : (
                            <div className="absolute inset-0 grid w-full h-full grid-cols-3 grid-rows-3 text-xs leading-[0] text-center text-transparent">
                              {(isMini ? [1, 2, 3, 4, 5, 6] : [1, 2, 3, 4, 5, 6, 7, 8, 9]).map(
                                (value) => (
                                  <div
                                    key={value}
                                    className={classNames(
                                      "flex items-center justify-center",
                                      cell[value] && "text-[#6F7D8D]"
                                    )}
                                  >
                                    {value}
                                  </div>
                                )
                              )}
                            </div>
                          )}
                          {solved && !isInitFilled && (
                            <motion.div
                              className="absolute inset-0 flex items-center justify-center"
                              initial={{ opacity: 0 }}
                              animate={{
                                opacity: 1,
                                transition: {
                                  delay: alreadyFinished
                                    ? 0
                                    : (1.8 / totalCellsForCheck) * cellIndexForCheck,
                                },
                              }}
                            >
                              {resultArray[cellIndex] ? (
                                <>
                                  {intl.locale === "ja" ? (
                                    <FiCircle className="w-full h-full text-red-500/50" />
                                  ) : (
                                    <FiCheck className="w-full h-full text-green-500/50" />
                                  )}
                                </>
                              ) : (
                                <>
                                  {intl.locale === "ja" ? (
                                    <FiX className="w-full h-full text-blue-500/50" />
                                  ) : (
                                    <FiX className="w-full h-full text-red-500/50" />
                                  )}
                                  <div className="absolute bottom-0 right-0 flex items-center justify-center w-2/3 text-base text-red-500 h-2/3">
                                    {solvedBoardArray[cellIndex]}
                                  </div>
                                </>
                              )}
                            </motion.div>
                          )}
                        </td>
                      )
                    })}
                  </>
                )}
              </tr>
            )
          })}
        </tbody>
      </table>
      <div>{Confetti}</div>
    </div>
  )
}
