import React from "react"
import { useState } from "react"
import { useNumberPlaceBoardContext } from "../context/NumberPlaceBoardContext"
import { Button } from "./Button"
import { Modal } from "../../src/components/Modal"
import { useIntl } from "react-intl"

export const ResetButton = () => {
  const { resetGame, stopwatchPause, stopwatchStart, solved, paused, handleOperation } =
    useNumberPlaceBoardContext()
  const intl = useIntl()

  const [isOpenResetModal, setIsOpenResetModal] = useState<boolean>(false)

  const openResetModal = () => {
    setIsOpenResetModal(true)
    stopwatchPause()
  }
  const closeResetModal = () => {
    setIsOpenResetModal(false)
    stopwatchStart()
  }

  return (
    <>
      <Button
        disabled={solved || paused}
        onClick={() => {
          openResetModal()
          handleOperation()
        }}
        className="w-full"
      >
        {intl.formatMessage({ id: "Restart" })}
      </Button>
      <Modal isOpen={isOpenResetModal} onClose={closeResetModal}>
        <div className="p-4 space-y-4">
          <div className="text-lg font-bold text-center">
            {intl.formatMessage({ id: "Restart?" })}
          </div>
          <div className="grid grid-cols-2 gap-4">
            <Button
              onClick={() => {
                closeResetModal()
              }}
            >
              {intl.formatMessage({ id: "Cancel" })}
            </Button>
            <Button
              isActive
              onClick={() => {
                resetGame()
                closeResetModal()
              }}
            >
              {intl.formatMessage({ id: "OK" })}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  )
}
