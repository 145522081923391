import React from "react"
import { useNumberPlaceBoardContext } from "../context/NumberPlaceBoardContext"
import { useIntl } from "react-intl"
import { FiHelpCircle } from "react-icons/fi"

export const HowToPlayButton = () => {
  const { openHowToPlayModal, handleOperation } = useNumberPlaceBoardContext()
  const intl = useIntl()

  return (
    <button
      onClick={() => {
        openHowToPlayModal()
        handleOperation()
      }}
      className="inline-flex items-center float-right text-blue-500 rounded-full"
    >
      <FiHelpCircle className="w-4 h-4 mr-1.5" />
      <span className="whitespace-nowrap">{intl.formatMessage({ id: "How To Play" })}</span>
    </button>
  )
}
