import { Formik } from "formik"
import React, { useState } from "react"
import Button from "./Button"
import { fetchApi } from "../lib/fetcher"
import toast, { Toaster } from "react-hot-toast"
import { TextField } from "./form/TextField"
import { object, string } from "yup"

export const AccountLinkForm = () => {
  const [isSucceeded, setIsSucceeded] = useState(false)

  return (
    <>
      <div className="mb-2 text-base font-bold">連携IDの登録</div>
      <div className="mb-4 text-sm">
        他のブラウザのプレイデータを引き継ぐには、ここに連携IDを登録しましょう。
      </div>
      <div>
        <Formik
          initialValues={{ linkCode: "", general: null }}
          validationSchema={object().shape({
            linkCode: string().required("連携IDを入力してください"),
          })}
          onSubmit={async ({ linkCode }, actions) => {
            setIsSucceeded(false)
            try {
              // 連携IDを発行
              const { is_succeeded } = await fetchApi(
                "/account_link_codes/link",
                "POST",
                JSON.stringify({
                  link_code: linkCode,
                })
              )
              setIsSucceeded(true)
              actions.setSubmitting(false)
              actions.resetForm()
            } catch (error) {
              if (error.data && error.data.message) {
                actions.setErrors({
                  general: error.data.message,
                })
              } else {
                actions.setFieldError("general", "エラーが発生しました")
              }
            } finally {
              actions.setSubmitting(false)
            }
          }}
        >
          {({ errors, handleSubmit, isSubmitting }) => (
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <TextField
                  name="linkCode"
                  type="text"
                  placeholder="連携IDをここに入力"
                  addClassName="text-lg font-bold text-center"
                />
              </div>
              <div className="text-center">
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  loading={isSubmitting}
                  rounded={false}
                  color="blue"
                  size={undefined}
                  fullwidth={undefined}
                  leftIcon={undefined}
                  rightIcon={undefined}
                  loaderColor={undefined}
                >
                  連携する
                </Button>
              </div>
              {errors.general && (
                <div className="mt-4 text-sm text-center text-red-500">{errors.general}</div>
              )}
              {isSucceeded && (
                <div className="mt-4 text-sm text-center text-green-500">連携が完了しました</div>
              )}
            </form>
          )}
        </Formik>
      </div>
    </>
  )
}
