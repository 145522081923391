import React, { useState, useEffect, useRef } from "react"
import ReactDOM from "react-dom"

import useSWR from "swr"

import { fetchApi } from "../src/lib/fetcher"
import { classNames } from "../src/utils/classNames"

export default function Header({ forwardingPath, kenteiHashId }) {
  // 左メニュー
  const [isLeftMenuOpen, setIsLeftMenuOpen] = useState(false)
  const leftMenuRef = useRef(null)
  useEffect(() => {
    const handleClickToClose = (event: any) => {
      const element = leftMenuRef.current
      if (!isLeftMenuOpen || element?.contains(event.target)) return
      setIsLeftMenuOpen(false)
    }

    window.addEventListener("click", handleClickToClose)
    return () => {
      window.removeEventListener("click", handleClickToClose)
    }
  }, [isLeftMenuOpen, leftMenuRef])

  // 右メニュー
  const [isRightMenuOpen, setIsRightMenuOpen] = useState(false)
  const rightMenuRef = useRef(null)
  useEffect(() => {
    const handleClickToClose = (event: any) => {
      const element = rightMenuRef.current
      if (!isRightMenuOpen || element?.contains(event.target)) return
      setIsRightMenuOpen(false)
    }

    window.addEventListener("click", handleClickToClose)
    return () => {
      window.removeEventListener("click", handleClickToClose)
    }
  }, [isRightMenuOpen, rightMenuRef])

  const { data, error } = useSWR("/current_user", fetchApi)
  if (error) return <div></div>
  if (!data) return <div></div>
  const { currentUser } = data

  return (
    <>
      {!currentUser && (
        <>
          <a
            onClick={() => {
              setIsLeftMenuOpen(!isLeftMenuOpen)
            }}
            className="flex items-center h-8 px-1 rounded-full cursor-pointer select-none hover:bg-gray-50"
          >
            <div className="flex items-center justify-center w-6 h-6 overflow-hidden rounded-full">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-5 h-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
          </a>
          {isLeftMenuOpen && (
            <div ref={leftMenuRef}>
              <div
                className="absolute left-0 z-40 w-64 py-2 overflow-hidden transition-transform duration-300 bg-white border rounded-lg shadow-2xl top-9 border-gray-50"
                role="presentation"
              >
                <ul>
                  <li className="mb-0">
                    <a
                      href={`/signup?next=${encodeURIComponent(forwardingPath)}`}
                      className="flex items-center h-10 px-4 font-bold hover:bg-gray-50"
                    >
                      無料会員登録
                    </a>
                  </li>
                  <li className="mb-0">
                    <a
                      href={`/login?next=${encodeURIComponent(forwardingPath)}`}
                      className="flex items-center h-10 px-4 font-bold hover:bg-gray-50"
                    >
                      ログイン
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          )}
        </>
      )}

      <Portal elementId="header-right-menu">
        <a
          onClick={() => {
            setIsRightMenuOpen(!isRightMenuOpen)
          }}
          className="flex items-center h-8 px-1 border rounded-full cursor-pointer select-none hover:bg-gray-50"
        >
          {currentUser && (
            <div className="flex items-center justify-center w-6 h-6 overflow-hidden bg-gray-100 rounded-full">
              {currentUser.avatar_image ? (
                <img src={currentUser.avatar_image} className="w-6 h-6" />
              ) : (
                <span className="text-gray-500">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-4 h-4"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </span>
              )}
            </div>
          )}

          {/* todo: level */}

          <div className="ml-1 text-gray-700">
            <svg
              className="w-4 h-4 fill-current"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
            </svg>
          </div>
        </a>
        {isRightMenuOpen && (
          <div ref={rightMenuRef}>
            <div
              className="absolute right-0 z-40 w-64 py-2 overflow-hidden transition-transform duration-300 bg-white border rounded-lg shadow-2xl top-9 border-gray-50"
              role="presentation"
            >
              {currentUser ? (
                <>
                  <div className="flex items-center px-4 py-2">
                    <div className="flex items-center justify-center overflow-hidden bg-gray-100 rounded-full h-9 w-9">
                      {currentUser.avatar_image ? (
                        <img src={currentUser.avatar_image} className="w-9 h-9" />
                      ) : (
                        <span className="text-gray-500">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-6 h-6"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                              clip-rule="evenodd"
                            />
                          </svg>
                        </span>
                      )}
                    </div>
                    <div className="w-40 ml-3">
                      <div className="truncate">{currentUser.display_name || "匿名"}さん</div>
                    </div>
                  </div>
                  <hr className="my-2 border-gray-100" />
                  <ul>
                    <li>
                      <a href="/mypage" className="flex items-center h-10 px-4 hover:bg-gray-50">
                        <span className="w-5 h-5 mr-3 text-gray-400">
                          <svg
                            className="w-5 h-5"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                              clip-rule="evenodd"
                            ></path>
                          </svg>
                        </span>
                        <span>マイページ</span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="/setting/profile"
                        className="flex items-center h-10 px-4 hover:bg-gray-50"
                      >
                        <span className="w-5 h-5 mr-3 text-gray-400">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-5 h-5"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z"
                              clip-rule="evenodd"
                            />
                          </svg>
                        </span>
                        <span>プロフィール設定</span>
                      </a>
                    </li>
                  </ul>
                  <hr className="my-2 border-gray-100" />
                  <ul>
                    <li>
                      <a
                        href="/logout"
                        className="flex items-center h-10 px-4 cursor-pointer hover:bg-gray-50"
                      >
                        <span className="w-5 h-5 mr-3 text-gray-400">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-5 h-5"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M3 3a1 1 0 00-1 1v12a1 1 0 102 0V4a1 1 0 00-1-1zm10.293 9.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L14.586 9H7a1 1 0 100 2h7.586l-1.293 1.293z"
                              clip-rule="evenodd"
                            />
                          </svg>
                        </span>
                        <span>ログアウト</span>
                      </a>
                    </li>
                  </ul>
                </>
              ) : (
                <ul>
                  <li>
                    <a href="/mypage" className="flex items-center h-10 px-4 hover:bg-gray-50">
                      <span className="w-5 h-5 mr-3 text-gray-400">
                        <svg
                          className="w-5 h-5"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                      </span>
                      <span>マイページ</span>
                    </a>
                  </li>
                </ul>
              )}
            </div>
          </div>
        )}
      </Portal>

      {currentUser?.role === "admin" && (
        <Portal elementId="admin-menu">
          <a
            href={`https://admin.quizcastle.com/admin/kentei/${kenteiHashId}/edit`}
            target="_blank"
            className="inline-flex items-center h-8 px-2 border rounded hover:border-gray-300 active:border-gray-400"
          >
            編集
          </a>
        </Portal>
      )}
    </>
  )
}

const Portal = ({ elementId, children }) => {
  return ReactDOM.createPortal(children, document.getElementById(elementId))
}
