import React, { useState } from "react"
import { FaPlay } from "react-icons/fa"
import {
  NumberPlaceBoardProvider,
  useNumberPlaceBoardContext,
} from "../number_place_game/context/NumberPlaceBoardContext"
import { GameSection } from "../number_place_game/components/GameSection"
import { classNames } from "../src/utils/classNames"
import { ResetButton } from "../number_place_game/components/ResetButton"
import { CheckAnswerButton } from "../number_place_game/components/CheckAnswerButton"
import { UndoButton } from "../number_place_game/components/UndoButton"
import { ToggleMemoButton } from "../number_place_game/components/ToggleMemoButton"
import { EraseButton } from "../number_place_game/components/EraseButton"
import { HintButton } from "../number_place_game/components/HintButton"
import { ShareModal } from "../number_place_game/components/ShareModal"
import { ShareArea } from "../number_place_game/components/ShareArea"
import { HowToPlayModal } from "../number_place_game/components/HowToPlayModal"
import Cookies from "js-cookie"
import { IntlProvider, useIntl } from "react-intl"
import { messages } from "../src/utils/i18n"
import { Toaster } from "react-hot-toast"
import { HowToPlayButton } from "../number_place_game/components/HowToPlayButton"
import { StopwatchButton } from "../number_place_game/components/StopwatchButton"
import { NumberButtons } from "../number_place_game/components/NumberButtons"
import { DropdownMenu } from "../number_place_game/components/DropdownMenu"
import { AccountLinkModal } from "../number_place_game/components/AccountLinkModal"

export type NumberPlaceBoard = {
  hashId: string
  isMini: boolean
  mode: string
  modeParam: string
  dailyChallengeDate: string
  dailyChallengeDateForDisp: string
  dailyChallengeNumber: string
  initBoardArray: string[]
  solvedBoardArray: string[]
  difficulty: string
}

const getDateStr = (date: Date) => {
  return `${date.getFullYear()}-${`0${date.getMonth() + 1}`.slice(-2)}-${`0${date.getDate()}`.slice(
    -2
  )}`
}

const NumberPlaceBoardApp = ({
  number_place_boards,
  isDailyChallengeMode,
  description,
  isLoggedIn,
  hasPlayData,
  from,
  is_admin,
  admin_url,
  locale,
}: {
  number_place_boards: Record<string, NumberPlaceBoard>
  isDailyChallengeMode: boolean
  description: string
  isLoggedIn: boolean
  hasPlayData: boolean
  from: string | null
  is_admin: boolean
  admin_url: string
  locale: string
}) => {
  // 今日
  const [today] = useState<Date>(new Date())
  const todayStr = getDateStr(today)

  // 明日の0時
  const nextDate = new Date(today.getTime())
  nextDate.setDate(nextDate.getDate() + 1)
  nextDate.setHours(0)
  nextDate.setMinutes(0)
  nextDate.setSeconds(0)
  nextDate.setMilliseconds(0)

  // ボードを取得
  const numberPlaceBoard =
    number_place_boards[todayStr] || number_place_boards[Object.keys(number_place_boards)[0]]

  // Cookie から完了済みの盤面情報を取得
  const finished_board_array = Cookies.get(
    `numplay_${numberPlaceBoard.hashId}_finished_board`
  )?.split("")
  const alreadyFinished = !!finished_board_array
  const finished_hint_cell_index_array = Cookies.get(
    `numplay_${numberPlaceBoard.hashId}_finished_hint_cell_index`
  )
    ?.split(",")
    ?.map((index) => Number.parseInt(index))
  const finished_seconds = Number.parseInt(
    Cookies.get(`numplay_${numberPlaceBoard.hashId}_finished_seconds`)
  )

  // 未ログイン、かつ、プレイデータがない、かつ、from がLINEの場合は、アカウント連携モーダルを表示
  const initialIsAccountLinkModalOpen = !isLoggedIn && !hasPlayData && from === "line"

  // デイリーチャレンジモード、かつ、終了してない、かつ、アカウント連携モーダルを表示しない場合は、遊び方モーダルを表示
  const initialIsHowToPlayModalOpen =
    isDailyChallengeMode && !alreadyFinished && !initialIsAccountLinkModalOpen

  // ストップウォッチ
  const stopwatchOffset = new Date(today.getTime())
  if (finished_seconds) {
    stopwatchOffset.setSeconds(stopwatchOffset.getSeconds() + finished_seconds)
  }
  const autoStart = !(
    alreadyFinished ||
    initialIsAccountLinkModalOpen ||
    initialIsHowToPlayModalOpen
  )

  return (
    <IntlProvider locale={locale} messages={messages[locale].numplay}>
      <NumberPlaceBoardProvider
        numberPlaceBoard={numberPlaceBoard}
        isDailyChallengeMode={isDailyChallengeMode}
        alreadyFinished={alreadyFinished}
        finishedBoardArray={finished_board_array}
        finishedHintCellIndexArray={finished_hint_cell_index_array}
        nextDate={nextDate}
        initialIsAccountLinkModalOpen={initialIsAccountLinkModalOpen}
        initialIsHowToPlayModalOpen={initialIsHowToPlayModalOpen}
        stopwatchOffset={stopwatchOffset}
        autoStart={autoStart}
      >
        <Game
          hashId={numberPlaceBoard.hashId}
          description={description}
          isAdmin={is_admin}
          adminUrl={admin_url}
        />
      </NumberPlaceBoardProvider>
    </IntlProvider>
  )
}

export default NumberPlaceBoardApp

const Game = ({
  hashId,
  description,
  isAdmin,
  adminUrl,
}: {
  hashId: string
  description: string
  isAdmin: boolean
  adminUrl: string
}) => {
  const {
    isMini,
    mode,
    dailyChallengeDateForDisp,
    paused,
    stopwatchStart,
    solved,
    allHint,
    shareText,
  } = useNumberPlaceBoardContext()
  const intl = useIntl()

  return (
    <>
      <div className="border-b">
        <div className="flex items-center justify-between max-w-md py-2 pl-4 pr-2 mx-auto space-x-2 md:py-3 md:max-w-screen-lg">
          <div className="flex items-center space-x-2">
            <div className="text-xl font-bold">{intl.formatMessage({ id: `title_${mode}` })}</div>
            {dailyChallengeDateForDisp && (
              <div className="px-3 py-1 leading-tight border-2 border-gray-300 rounded-full">
                {dailyChallengeDateForDisp}
                {intl.formatMessage({ id: "daily challenge date", defaultMessage: " " })}
              </div>
            )}
          </div>
          <div className="flex items-center space-x-2">
            <StopwatchButton />
            {intl.locale === "ja" && <DropdownMenu isNarrow={false} />}
          </div>
        </div>
      </div>
      <div className="relative w-full max-w-md px-4 pb-6 mx-auto mt-2 md:mt-4 md:max-w-screen-lg">
        <div className="mb-2 text-gray-500 after:block after:clear-both md:mb-4">
          {description}
          <HowToPlayButton />
        </div>
        <div className="grid w-full grid-cols-1 gap-4 md:gap-8 md:grid-cols-2">
          <div className="relative">
            <GameSection />
            {!solved && paused && (
              <div className="absolute inset-0 flex items-center justify-center">
                <button onClick={() => stopwatchStart()} className="bg-[#0072E2] rounded-full p-4">
                  <FaPlay className="w-6 h-6 text-white" />
                </button>
              </div>
            )}
          </div>

          <div className={classNames("relative flex flex-col")}>
            <ShareArea position="default" eventLabel="default" />
            <div
              className={classNames(
                "grid flex-1 gap-2 mb-4 font-mono md:grid-cols-3",
                isMini ? "grid-cols-6" : "grid-cols-9"
              )}
            >
              <NumberButtons />
            </div>
            <div className="grid grid-cols-4 gap-2 mb-2 text-center md:mb-3">
              <UndoButton />
              <EraseButton />
              <ToggleMemoButton />
              <HintButton />
            </div>
            <div className="grid grid-cols-3 gap-2">
              <div className="col-span-2">
                <CheckAnswerButton />
              </div>
              <div>
                <ResetButton />
              </div>
            </div>
          </div>
        </div>

        {isAdmin && (
          <div className="relative mt-4 border">
            <div className="absolute top-0 left-0 px-1 bg-gray-200 text-xxs">
              開発者のみ (ボードID: {hashId})
            </div>
            <div className="flex p-4 pt-8 space-x-4">
              <a
                href={`${adminUrl}/admin/number_place_boards/${hashId}/edit`}
                target="_blank"
                className="px-3 py-2 border border-gray-300 rounded-md hover:bg-gray-100"
              >
                ボードを編集
              </a>
              <button
                onClick={() => {
                  allHint()
                }}
                className="px-3 py-2 border border-gray-300 rounded-md hover:bg-gray-100"
              >
                全マス埋める
              </button>
              <button
                onClick={() => {
                  Cookies.remove(`numplay_${hashId}_finished_board`)
                  Cookies.remove(`numplay_${hashId}_finished_hint_cell_index`)
                  Cookies.remove(`numplay_${hashId}_finished_seconds`)
                  location.href = location.href
                }}
                className="px-3 py-2 border border-gray-300 rounded-md hover:bg-gray-100"
              >
                解答記録を消してリロード
              </button>
            </div>
          </div>
        )}

        <ShareModal />
        <AccountLinkModal />
        <HowToPlayModal />
        <Toaster
          position="top-center"
          toastOptions={{
            className: "font-sans",
            style: {
              border: "none",
              padding: "4px",
              background: "rgba(0,0,0,75%)",
              color: "white",
            },
          }}
          containerStyle={{
            top: 100,
          }}
        />
      </div>
    </>
  )
}
