import { object, string, bool, mixed } from "yup"

export default object().shape({
  is_published: bool(),
  title: string()
    .typeError("不正な値です")
    .required("入力してください")
    .max(50, "50文字以内で入力してください"),
  top_image: mixed(),
  remove_top_image: bool(),
})
