import React, { useEffect, useState } from "react"
import LoaderContainer from "../src/components/LoaderContainer"
import { fetchApi } from "../src/lib/fetcher"

const AccountLinkApp = ({ link_code }: { link_code: string }) => {
  const [is_succeeded, setIsSucceeded] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)

  useEffect(() => {
    // 連携IDを発行
    const fetchAccountLink = async () => {
      if (link_code) {
        try {
          const { is_succeeded } = await fetchApi(
            "/account_link_codes/link",
            "POST",
            JSON.stringify({
              link_code,
            })
          )
          setIsSucceeded(true)
        } catch (error) {
          if (error.data && error.data.message) {
            setErrorMessage(error.data.message)
          } else {
            setErrorMessage("エラーが発生しました")
          }
        }
      } else {
        setErrorMessage("連携IDが不正です")
      }
    }
    fetchAccountLink()
  }, [])

  return is_succeeded ? (
    <div className="max-w-screen-sm px-4 py-8 mx-auto text-center">
      <div className="mb-4 text-lg font-bold">連携が完了しました</div>
      <div className="">
        <a href="/" className="text-blue-500">
          ホームに戻る →
        </a>
      </div>
    </div>
  ) : errorMessage ? (
    <div className="max-w-screen-sm px-4 py-8 mx-auto text-center">
      <div className="mb-4 text-lg font-bold">連携に失敗しました</div>
      <div className="mb-4">{errorMessage}</div>
      <div className="">
        <a href="/" className="text-blue-500">
          ホームに戻る →
        </a>
      </div>
    </div>
  ) : (
    <div className="max-w-screen-sm p-4 mx-auto text-center">
      <LoaderContainer />
    </div>
  )
}
export default AccountLinkApp
