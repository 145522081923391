import React from "react"
import { Button } from "./Button"
import { useNumberPlaceBoardContext } from "../context/NumberPlaceBoardContext"

export const NumberButtons = () => {
  const { isMini, solved, paused, memoMode, toggleMemo, fillNumber, handleOperation } =
    useNumberPlaceBoardContext()

  const onClickNumber = (number: number) => {
    if (memoMode) {
      toggleMemo(number.toString())
    } else {
      fillNumber(number.toString())
    }
  }

  return (
    <>
      {(isMini ? [1, 2, 3, 4, 5, 6] : [1, 2, 3, 4, 5, 6, 7, 8, 9]).map((number) => (
        <Button
          key={number}
          disabled={solved || paused}
          onClick={() => {
            onClickNumber(number)
            handleOperation()
          }}
          className="text-2xl"
        >
          {number}
        </Button>
      ))}
    </>
  )
}
