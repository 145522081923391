import React, { useRef, useState } from "react"
import { FaLightbulb } from "react-icons/fa"
import { classNames } from "../../src/utils/classNames"
import { useNumberPlaceBoardContext } from "../context/NumberPlaceBoardContext"
import { Button } from "./Button"
import { useIntl } from "react-intl"
import { useOnClickOutside } from "../../src/hooks/useClickOutside"
import { FiX } from "react-icons/fi"

export const HintButton = () => {
  const { hint, solved, paused, handleOperation, secondsFromLastOpration } =
    useNumberPlaceBoardContext()
  const intl = useIntl()

  const [closedOnce, setClosedOnce] = useState(false)
  const handleHintTipsClose = () => {
    handleOperation()
    setClosedOnce(true)
  }

  // 外側のクリックイベントを設定するためのref
  const ref = useRef()
  useOnClickOutside(ref, handleHintTipsClose)

  return (
    <div className="relative">
      <div className="mb-1 md:mb-2">
        <Button
          disabled={solved || paused}
          onClick={() => {
            hint()
            handleOperation()
          }}
          className="w-full rounded-full"
        >
          <FaLightbulb className="inline-block w-6 h-6" />
        </Button>
      </div>
      <div
        className={classNames(
          "text-xs font-bold text-[#0072E2]",
          (solved || paused) && "opacity-30"
        )}
      >
        {intl.formatMessage({ id: "Hint" })}
      </div>
      {!closedOnce && secondsFromLastOpration > 9 && (
        <button
          ref={ref}
          className="absolute z-10 block border-red-500 -bottom-1 rounded-3xl -inset-x-2 -top-2 border-3"
          onClick={handleHintTipsClose}
        >
          <div className="absolute top-0 pb-2 -translate-y-full right-1 lg:-right-0.5">
            <div className="px-3 py-2 bg-red-500 rounded">
              <div className="text-white whitespace-nowrap">
                {intl.formatMessage({ id: "Use Hint to get the answer to one square." })}
              </div>
            </div>
            <div className="absolute top-0 right-0 translate-x-1/2 -translate-y-1/2">
              <div className="p-1 bg-white border border-red-500 rounded-full">
                <FiX className="w-4 h-4 text-gray-400" />
              </div>
            </div>
          </div>
        </button>
      )}
    </div>
  )
}
