import React, { useEffect, useRef } from "react"
import { useState } from "react"
import { classNames } from "../../src/utils/classNames"
import { useNumberPlaceBoardContext } from "../context/NumberPlaceBoardContext"
import { Button } from "./Button"
import { Modal } from "../../src/components/Modal"
import { useIntl } from "react-intl"
import { useOnClickOutside } from "../../src/hooks/useClickOutside"
import { FiX } from "react-icons/fi"

export const CheckAnswerButton = () => {
  const {
    checkAnswer,
    stopwatchPause,
    stopwatchStart,
    solved,
    paused,
    isAllFilldOnce,
    alreadyFinished,
    handleOperation,
  } = useNumberPlaceBoardContext()
  const intl = useIntl()

  const [isOpenCheckAnswerModal, setIsOpenCheckAnswerModal] = useState<boolean>(false)
  const openCheckAnswerModal = () => {
    setIsOpenCheckAnswerModal(true)
    stopwatchPause()
  }
  const closeCheckAnswerModal = () => {
    setIsOpenCheckAnswerModal(false)
    stopwatchStart()
  }

  useEffect(() => {
    if (!alreadyFinished && isAllFilldOnce) {
      openCheckAnswerModal()
    }
  }, [isAllFilldOnce])

  return (
    <>
      <div className="relative">
        <Button
          disabled={solved || paused}
          isActive={!solved}
          onClick={() => {
            openCheckAnswerModal()
            handleOperation()
          }}
          className={classNames("w-full", !solved && "bg-blue-500")}
        >
          {intl.formatMessage({ id: "Check answer" })}
        </Button>
      </div>
      <Modal isOpen={isOpenCheckAnswerModal} onClose={closeCheckAnswerModal}>
        <div className="p-4 space-y-4">
          <div className="text-lg font-bold text-center">
            {intl.formatMessage({ id: "Check answer?" })}
          </div>
          <div className="grid grid-cols-2 gap-4">
            <Button
              onClick={() => {
                closeCheckAnswerModal()
              }}
            >
              {intl.formatMessage({ id: "Cancel" })}
            </Button>
            <Button
              isActive
              onClick={() => {
                // GAイベント
                //window.dataLayer = window.dataLayer || []
                //window.dataLayer.push({
                //  event: "numplay_check_answer",
                //  event_params: {
                //    action: "numplay_check_answer",
                //  },
                //})

                checkAnswer()
                setIsOpenCheckAnswerModal(false)
              }}
            >
              {intl.formatMessage({ id: "OK" })}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  )
}
